import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'src/app/base.service';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
import { PaginationComponent } from 'src/app/pagination/pagination.component';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
@Component({
  selector: 'app-gapsheet-file',
  templateUrl: './gapsheet-file.component.html',
  styleUrls: ['./gapsheet-file.component.css']
})
export class GapsheetFileComponent implements OnInit {
   submitted: boolean;
   errorMsg: string;
   isFilterSet: boolean;
   filterStartDate:any
   filterEndDate :any;

  constructor( private fb:FormBuilder,private router:Router,private baseService:BaseService, private sharedService:SharedService) { }
  totalCount : number;
  page: Number =0;
  urlLink : String ;
  allFiles : any;
  toggle=true;

  dateFilterForm: FormGroup;
  @ViewChild(PaginationComponent ) page_comp: PaginationComponent ;

  ngOnInit(): void {
    this.isFilterSet = false;
    this.getAllFiles(this.sharedService.getPageNumber());

    this.dateFilterForm = this.fb.group({
      startDate:new FormControl('',Validators.required),
      endDate:new FormControl('',Validators.required),
    });
  }

  onFilterSubmit(){
    this.submitted = true
    this.errorMsg="";
    let startDate =new Date( this.dateFilterForm.controls.startDate.value);
    let endDate = new Date( this.dateFilterForm.controls.endDate.value);

    if(this.dateFilterForm.invalid && this.submitted){
      this.errorMsg ="Please enter date"
    }
    else if(endDate.getTime() - startDate.getTime()<0){
      this.errorMsg="Invalid End Date"
    }
    else {
      this.sharedService.setPageNumber(1)
      this.getFilteredFiles(this.sharedService.getPageNumber())
      this.page_comp.ngOnInit()
      document.getElementById( 'filter-pop-up-tab').style.display='none'

    }
  }
  hasGapSheet(file){

      if(new Date(file.uploadTime).getTime()- new Date('07/23/2020').getTime()>0){
        return true;
      }


    return false
  }
  goToDashboard(file){
    if(this.hasGapSheet(file)) {

      let documentJson = {id: file._id, outputDocumentLink: file.outputDocumentLink,sourceDocumentLink : file.sourceDocumentLink  , documentName: file.documentName}
      this.router.navigate(['/dashboard'],
        {queryParams: {upload: JSON.stringify(documentJson)}}
      );
      this.sharedService.setGapSheetOriginCall('/gapsheetFile')
    }
  }

  getAllFiles(page)
  {
    this.urlLink = 'gapsheet/list/page/'+page;
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
        this.allFiles = data.result.list;
        this.totalCount = data.result.totalCount;
      });
  }
  getFilteredFiles(page){
    this.urlLink = 'filter/gapsheet/'+page+'/startDate/'+this.dateFilterForm.controls.startDate.value+'/endDate/'+this.dateFilterForm.controls.endDate.value;

    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
        this.allFiles = data.result.list;
        this.totalCount = data.result.totalCount;
        this.isFilterSet = true;
      });
  }
  closeFilter(){
    this.toggle=true;
    document.getElementById( 'filter-pop-up-tab').style.display='none'
  }

  reload(){
    if(!this.isFilterSet)
      this.getAllFiles(this.sharedService.getPageNumber());
    else
      this.getFilteredFiles(this.sharedService.getPageNumber());

  }

  clearFilterFields(){
      this.toggle=true;
    this.isFilterSet = false;
    this.reload();
    this.dateFilterForm.controls.startDate.reset();
    this.dateFilterForm.controls.endDate.reset();

  }
  clearFilteredData(){
    this.isFilterSet = false
    this.reload();
    this.dateFilterForm.controls.startDate.reset();
    this.dateFilterForm.controls.endDate.reset();
  }
  filter(){
    this.toggle=!this.toggle;
      document.getElementById('filter-pop-up-tab').style.display='block';
  }




}
