<div class="main_left_container">
  <form name="loginForm">
    <div class="text_tab">
      <span class="uploadtxt">Split PDF Documents</span>
    </div>
    <div class="rectangle_box" appDnd (fileDropped)="onFileDropped($event)">
      <div class="upload_file">
        <img class="upload-icon" src="assets\img\split.png"  >
      </div>
      <div class="droplink">
        <p class="drop-files-here-or-c">
          Drop files here or click <a href="" data-toggle="modal" data-target="#myModal"> select
          to upload files</a></p>
      </div>
    </div>

    <div class="uploading-files-modal fade" id="myModal" role="dialog" [style.display]="showModal?'block':'none'">
      <div class="rectangle-main">
        <div class="uploading-file">
          <span class="uploading-files">UPLOAD FILES</span>
          <span data-dismiss="modal" (click)="closeModal()" class="close-text">CLOSE</span>
        </div>
        <form [formGroup]="uploadOuterFileForm" class="upload_popup_outercontainer"
              (ngSubmit)=uploadFiles($event)>
          <div *ngIf="uploadOuterFileForm.controls.uploadItems['controls'].length>0">
            <div formArrayName="uploadItems"
                 *ngFor="let file of uploadOuterFileForm.get('uploadItems')['controls'] ; let i = index">
              <div [formGroupName]="i">
                <div class="upload_popup_innercontainer">
                  <div class="up_icons">
                    <img *ngIf="!file.controls.file.value.type.includes('spreadsheet')&& !file.controls.file.value.type.includes('excel')"
                         class="up_icon_img" src="assets\img\PDF-8.png">
                    <img *ngIf="file.controls.file.value.type.includes('spreadsheet')||file.controls.file.value.type.includes('excel')"
                         class="up_icon_img" src="assets\img\XLS-8.png">


                  </div>

                  <div class="docname">
                                        <span *ngIf="greaterThan(file.controls.file.value.name.length, 20)"
                                              class="doc-name-bigText">{{(file.controls.file.value.name| slice:0:15)+' ... '+(file.controls.file.value.name| slice:file.controls.file.value.name.length-10:file.controls.file.value.name.length)}}</span>
                    <span *ngIf="!greaterThan(file.controls.file.value.name.length, 20)"
                          class="doc-name-bigText">{{(file.controls.file.value.name)}}</span><br>
                    <span class="upload-form-errorMsg">{{errorMsg}}</span>

                  </div>
                  <div class="docpass">
                    <input type="text" type="password" formControlName="password"
                           class="upload-popup-input" name="password" value=""
                           placeholder="Document password">

                  </div>
                  <div class="doctag">
                    <input type="text" class="upload-popup-input" formControlName="splitIndex" name="splitIndex"
                           placeholder="page numbers to split pdf : in (, , , ,) format">

                  </div>
                  <div class="remove-doc">
                    <span (click)="removeDocFromPopUp(i)" class="material-icons">close</span>
                  </div>
                  <div class="line-dash"></div>
                  <!-- <div *ngIf="uploadFileForm.invalid" class="select-file">Please select file</div> -->
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="uploadOuterFileForm.controls.uploadItems['controls'].length==0" class="please-select-file">Please select file</div>
          <app-spinner [showSpinner]="spinner"></app-spinner>
          <div class="upload-popup-button-container">
            <button type="button" class="add-more-file-btn">
              <input #fileInput type="file" name="filesList" formControlName="file"
                     (change)="onSelectFile($event.target.files)" id="file" class="inputfile" required
                     multiple />
              {{uploadButtonText}}</button>
            <button [disabled]="uploadOuterFileForm.controls.uploadItems['controls'].length==0" class="extract-file-btn">SPLIT</button>
          </div>
        </form>
      </div>
    </div>


  </form>
    <div class="middle_container">
        <div class="recent_title">
            <span class="txt">Recent Documents</span>
        </div>
    </div>
    <div class="lower_container">
        <table cellpadding="2">
            <tr>
                <th class="first_col_th">Document Name</th>
                <th>Password</th>
                <th>Type</th>
                <th>
                    <div class="table_header">
                        <span class="header_name">Upload Time</span>

                    </div>
                </th>

                <th>Status</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let upload of uploads">
                <td class="first_col"title="{{upload.documentName}}">{{upload.documentName}}</td>
                <td>{{upload.inputDocumentPassword}}</td>
                <td class="third_col">{{upload.documentFormat}}</td>
                <td>{{upload.uploadTime|date}}<br><span class="time">{{upload.uploadTime|date :'shortTime'}}</span></td>

                <td>{{upload.approvalStatus}}</td>
            
                
                <td>
                    <div class="Action">
                      <i class="material-icons md-get_app" (click)="downloadOutput(upload)">get_app</i>
                      <i class="material-icons md-picture_as_pdf"  (click)="downloadpdf(upload)">picture_as_pdf</i>
                        <i class="material-icons md-delete" (click)="fileToDelete(upload)"  onclick="document.getElementById('deleteclass').style.display='block'" >delete</i>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination>
</div>


<!-- Delete pop-up html starts -->

<div id="deleteclass" class="w3-modal">
  <div class="w3-container w3-teal">
    <div class="Inner-tab">
      <div class="tittle-box">
        <label class="warning">WARNING</label>
      </div>
      <div class="are-you-sure-message">

        <span>Are you sure you want to delete "{{documenttoDeleteName}}" file ?</span>
      </div>
      <div class="btn-tab">
        <button class="cancle" onclick="document.getElementById('deleteclass').style.display='none'">CANCEL</button>
        <button class="yes" (click)="delete()"  onclick="document.getElementById('deleteclass').style.display='none'" >YES</button>
      </div>
    </div>
  </div>
</div>

<!-- Delete pop-up html Finish Here -->
