import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable()
export class SharedService {

  PageNumber :number =1;
  public lastPage :boolean = false;

  public gapsheetPath:string='/upload' ;
  // Observable string sources
  private missionAnnouncedSource = new Subject<boolean>();

  // Observable boolean streams
  loginSuccessful$ = this.missionAnnouncedSource.asObservable();

  // Service message commands
  loginResult(success: boolean) {
    this.missionAnnouncedSource.next(success);
  }
  setPageNumber(value : number) {
    this.PageNumber = value;
  }

  getPageNumber() {
    return this.PageNumber;
  }
  setGapSheetOriginCall(path){
    this.gapsheetPath = path;
  }
  getGapSheetOriginCall(){
    return this.gapsheetPath;
  }
}
