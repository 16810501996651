
<div class="main_left_container">


    <div class="middle_container">
        <div class="recent_title">
            <span class="txt">API Failure</span>
        </div>

        <select name="STATUS" (change)="selectStatus($event.target.value)"  id="status">
          <option value="E">ALL</option>
          <option value="REJECTED">ERROR</option>
          <option value="PENDING">READY</option>
        </select>

        <button *ngIf="isSuperAdminUser" (click) = "reparseAllFiles()" id="btn" class="view_btn"><span class="view_txt" >REPARSE All</span></button>
       
  

    </div>

    <app-spinner class="spinner" [showSpinner]="spinner"></app-spinner>


    <div class="lower_container">

        <table cellpadding="2">

                 <tr>
                    <th>
                      <div class="table_header">
                   <span class="header_name">Company Name</span>
                     </div>
                  </th>

                    <th>
                      <div class="table_header">
                        <span class="header_name">Upload Time</span>
                   </div>
                  </th>
                    <th>  <div class="table_header">
                      <span class="header_name">Document Name</span>
                 </div></th>


                    <th>Password</th>
                    <th>  <div class="table_header">
                      <span class="header_name">Document Format</span>
                 </div></th>

                 <th>  <div class="table_header">
                  <span class="header_name">Output Type</span>
             </div></th>
             <th>  <div class="table_header">
              <span class="header_name">Input Doc Type</span>
         </div></th>

                    <th>Status</th>
                    <th>Action</th>
                  </tr>

                    <tr *ngFor="let upload of uploads">
                      <td>{{upload.company?.displayName}}</td>
                      <td>{{upload.uploadTime|date }}<br><span class="time">{{upload.uploadTime|date :'shortTime' }}</span></td>
                      <td title="{{upload.documentName}}" class="doc-name-col">{{upload.documentName}}</td>

                      <td>{{upload.inputDocumentPassword}}</td>
                      <td title="{{upload.documentFormat}}" class="doc-name-col">{{upload.documentFormat}}</td>
                      <td title="{{upload.outputFormatRequested}}" class="doc-name-col">{{upload.outputFormatRequested}}</td>
                      <td title="{{upload.inputDocumentFormat}}" class="doc-name-col">{{upload.inputDocumentFormat}}</td>

                      <td *ngIf="upload.approvalStatus==='PENDING'" >   
                        <button class="Status-READY"> 
                        READY
                       </button></td>
                      <td *ngIf="upload.approvalStatus==='REJECTED'">
                        <button class="Status-ERROR"> 
                         ERROR
                      </button>
                    </td>
                      <td *ngIf="upload.approvalStatus==='APPROVED'">
                        <button class="Status-VALIDATED"> 
                          VALIDATED
                       </button>
                      </td>


        <td>
          <div class="Action">
            <i class="material-icons md-get_app" (click)="downloadOutput(upload)">get_app</i>
            <i class="material-icons md-picture_as_pdf"  (click)="downloadpdf(upload)">picture_as_pdf</i>
            <i class="material-icons md-delete" (click)="fileToDelete(upload)" onclick="document.getElementById('delete-pop-up-tab').style.display='block'" >delete</i>

            <div *ngIf="!upload.documentFormat.includes('CONSOLIDATED')" class="btn">
              <button  class="btn2"(click)="reparseData(upload)">REPARSE</button></div>
             
            </div></td>
      </tr>
    </table>

            </div>
            <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination>
            </div>


<!-- Delete pop-up html starts -->

<div id="delete-pop-up-tab" class="delete-pop-up-modal">
  <div class="delete-outer-tab">
    <div class="delete-inner-tab">
      <div class="delete-warning-tittle-box">
        <label class="delete-warning-tittle">WARNING</label>
      </div>
      <div class="delete-warning-message ">

        <span>Are you sure you want to delete "{{documentToDeleteName}}" file ?</span>
      </div>
      <div class="delete-btn-tab">
        <button class="delete-cancel" onclick="document.getElementById('delete-pop-up-tab').style.display='none'">CANCEL</button>
        <button class="delete-yes" (click)="delete()" onclick="document.getElementById('delete-pop-up-tab').style.display='none'">YES</button>
      </div>
    </div>
  </div>
</div>

<!-- Delete pop-up html Finish Here -->


