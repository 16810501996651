import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
 
  gotoInvoice(){
    this.router.navigate(['/invoice']); 
  }

}
