
<div class="main_left_container" id="parentElement" #parentElement>
    <div class="container">
        <label id="parent-path" class="upload-dashboard-text"><a style="font-size: 14px;    cursor: pointer;text-decoration-line: underline;" [routerLink]="parentRouter"  >{{parentRouterName}}</a> > Gapsheet</label> <br>
        <label id="report-link" class="upload-dashboard-text" style="display: none">Visit us at www.gapsheet.com </label> <br>


        <div class="outer_header">
            <div class="over_header">
                <span class="Over-All-Performance">Over All Performance</span></div>
          <button id="download-btn-pdf" class="download-btn-pdf"(click)="downloadPDFReport()">
            <div class="both_filter">
            <i class="material-icons md-get_app" style="margin-right: 8%" >get_app</i> PDF Screenshot</div></button>
         
          <button  id ="download-btn-xls" class="download-btn-xls"(click)="downloadOutput()" ><div class="both_filter"><i class="material-icons md-get_app"  style="margin-right: 8%" >get_app</i> Detailed XLS</div></button>
        </div>

        <div class="tab-1">
            <div class="filters-tab">
                <label class="Filters">DETAILS</label>
                <div class="filters-box">
                  <label class="filter-box-label">DOCUMENT DATE <i class="material-icons help-outline" style="color:black;" title="All data below is as per this date">help_outlline</i></label>

                  <input  value="{{summaryData.endDate}}" disabled >

                  <label class="filter-box-label">DOCUMENT TYPE</label>

                  <input id="advisor" value="{{summaryData.documentFormat}}" disabled >

                   <label class="filter-box-label">DOCUMENT NAME</label>

                   <button  id ="download-btn-source" class="download-btn-source "(click)="downloadSourcePDF()"  title="{{uploadDocument.documentName}}" >
                    <div class="both_filter"><i class="material-icons md-get_app"  style="margin-right: 2%" >get_app</i>{{uploadDocument.documentName}}</div></button>

</div>
            </div>

            <div class="graph-tab">

                  <div echarts class="graph-css" [style.display]="isLineChartAvailable?'block':'none'" [options]="lineChartOptions" [autoResize]="true" ></div>
                  <div [style.display]="!isLineChartAvailable?'block':'none'"  class="graph-css" >
                  <div class="chart-unavailable" >
                    INSUFFICIENT DATA
                  </div>


                  </div>
                  <div *ngIf="holdingData['holdingsWithoutAmfiCode']?.length>0" class="holdings-withoutAmfiCode"  >
                    <span style="cursor: pointer;"  title="*The following schemes are not included in chart above: {{holdingsWithoutAmfiCode}}" >*The following schemes are not included in chart above: {{holdingsWithoutAmfiCode}}</span>
                  </div>

            </div>
        </div>

        <div class="tab-2"  >

            <div class="summary-tab-boxes" [style.background-image]="'linear-gradient(306deg, #03bdd9 -1%, #0095ab 100%)'"style="margin-left:1%">
                <div class="summary-tab-boxes-heading-container">
                    <div class="Oval-shape">
                        <img class="portfolio-icon" alt="portfolio" src="assets\img\portfolio-value.png">
                    </div>
                    <label class="summary-tab-boxes-heading">Portfolio Value</label>
                </div>
                <div class="box-2">
                    <img class="dollar" alt="amountInvested" src="assets\img\Pricing-8.png" />
                    <label class="dollar-text-tab" title="{{summaryData.closingNetAmount| number:'.2-2' }}" >{{summaryData.closingNetAmount| shortNumber }}</label>
                </div>
                <div class="box-3">
                  <div class="box-lower-container"[style.color]="'#fff'">
                    <div class="box-lower-container-inner">
                      <div class="box-lower-left">IRR (%)</div>
                      <div class="box-lower-right" *ngIf="!summaryData?.returnsCalculationRemarks?.includes('compute returns')"  >{{summaryData?.annualizedReturn | number:'.2-2'}}</div>
                      <div class="box-lower-right" *ngIf="summaryData.returnsCalculationRemarks?.includes('compute returns')"  >N.A.</div>
                    </div>
                    <div class="box-lower-container-inner">
                      <div class="box-lower-left">Risk Level</div>
                      <div class="box-lower-right">{{riskLevel}}</div>
                    </div>
                  </div>

                </div>
            </div>

          <div class="summary-tab-boxes" [style.background-image]="'linear-gradient(141deg, #1f35bd -5%, #6568fb 80%)'">
                <div class="summary-tab-boxes-heading-container">
                    <img class="Oval-shape" alt="oval" src="assets\img\Amount Invested-8 (1).png">
                    <label class="summary-tab-boxes-heading">Net Amount Invested<i class="material-icons help-outline" title=" Net Amount Invested =  Total Purchases  -  Total Withdrawn">help_outlline</i></label>
                </div>
                <div class="box-2">
                    <img class="dollar" alt="pricing" src="assets\img\Pricing-8.png" />
                    <label class="dollar-text-tab" *ngIf="!summaryData.returnsCalculationRemarks.includes('compute returns')"  title="{{summaryData.amountInvested - summaryData.amountWithdrawn| number:'.2-2'}}" >{{summaryData.amountInvested - summaryData.amountWithdrawn|shortNumber}}</label>
                    <label class="dollar-text-tab" *ngIf="summaryData.returnsCalculationRemarks.includes('compute returns')"   >{{'N.A.'}}</label>
                </div>
                <div class="box-3">
                  <div class="box-lower-container"[style.color]="'#fff'">
                    <div class="box-lower-container-inner">
                      <div class="box-lower-left">Purchases (Rs)</div>
                      <div class="box-lower-right" *ngIf="!summaryData.returnsCalculationRemarks.includes('compute returns')"   title="{{summaryData.amountInvested | number:'.2-2'}}" >{{summaryData.amountInvested | shortNumber}}</div>
                      <div class="box-lower-right" *ngIf="summaryData.returnsCalculationRemarks.includes('compute returns')"  >{{'N.A.'}}</div>

                    </div>
                    <div class="box-lower-container-inner">
                      <div class="box-lower-left">Redemption + Dividends (Rs)</div>
                      <div class="box-lower-right"  *ngIf="!summaryData.returnsCalculationRemarks.includes('compute returns')"  title="{{summaryData.dividendPaid +summaryData.amountWithdrawn | number:'.2-2'}}" >{{summaryData.dividendPaid +summaryData.amountWithdrawn | shortNumber}}</div>
                      <div class="box-lower-right" *ngIf="summaryData.returnsCalculationRemarks.includes('compute returns')"  >{{'N.A.'}}</div>

                    </div>
                  </div>
                </div>
            </div>

          <div class="summary-tab-boxes" [style.background-image]="'linear-gradient(309deg, #ffc14e 8%, #f26709 114%)'">
                <div class="summary-tab-boxes-heading-container">
                    <img class="Oval-shape" alt="amountWithdrawn" src="assets\img\Amount Withdrawn-8.png">
                    <label class="summary-tab-boxes-heading">Absolute Gain</label>
                </div>
                <div class="box-2">
                    <img class="dollar" alt="Absolutegain" src="assets\img\Pricing-8.png" />
                    <label class="dollar-text-tab" title="{{summaryData.unrealizedProfitorLoss | number:'.2-2'}}" >{{summaryData.unrealizedProfitorLoss | shortNumber}}</label>
                </div>
                <div class="box-3">
                  <div class="box-lower-container"[style.color]="'#fff'">
                    <div class="box-lower-container-inner">
                      <div class="box-lower-left">Absolute Return (%)</div>
                      <div class="box-lower-right" *ngIf="!summaryData.returnsCalculationRemarks.includes('compute returns')"  >{{summaryData.absoluteReturn | number:'.2-2'}}</div>
                      <div class="box-lower-right" *ngIf="summaryData.returnsCalculationRemarks.includes('compute returns')"  >{{'N.A.'}}</div>

                    </div>
                    <div class="box-lower-container-inner">
                    <div class="box-lower-left">3 Year IRR (%)</div>
                    <div class="box-lower-right">{{summaryData.annualizedReturn_3years | number:'.2-2'}}</div>
                  </div>
                  </div>

                </div>
            </div>

          <div class="summary-tab-boxes">
                <div class="summary-tab-boxes-heading-container">
                    <img class="Oval-shape" alt="sip" id="Oval-shape" src="assets\img\Acitve SIPs-8.png">
                    <label class="summary-tab-boxes-heading" [style.color]="'#000'">Active SIPs<i class="material-icons help-outline" style="color:black;" title="SIPs done in the last month">help_outlline</i></label>
                </div>
                <div class="box-2">
                    <label class="ratio-text">{{summaryData.sipCount | number}}</label>
                    <button class="sip-btn"data-toggle="modal" (click)="getSipHoldings()"  [ngClass]="holdingtable?'sip-btn':'sip-btn-enabled'"data-target="#sip-holding">SIPs</button>
                </div>
                <div class="box-3">
                  <div class="box-lower-container"[style.color]="'#000'">
                    <div class="box-lower-container-inner">
                      <div class="box-lower-left">Last Month SIP Amount</div>
                      <div class="box-lower-right" title="{{summaryData.sipAmount | number:'.2-2'}}">{{summaryData.sipAmount | shortNumber}}</div>
                    </div>   <div class="box-lower-container-inner">
                      <div class="box-lower-left">No of ARN</div>
                      <div class="box-lower-right">{{summaryData.NoOfArn | number}}</div>
                    </div>
                  </div>
                  <div id="sip-holding" role="dialog" class="holdingtable"style="z-index: 3;width: 39%;left: 29%"(click)="hold()">
                    <div  id="sipHolding-modal-content">
                      <table cellpadding="1" id="sipHolding-myTable">
                        <thead style="text-decoration: none;">
                        <th >Scheme Name</th>
                        <th>Total Units</th>
                        <th>Current Value (Rs)</th>
                        <th>3 Year IRR (%)</th>
                        <th>Tax Applicable (Rs) <i class="material-icons help-outline" style="color:black;" title="Tax payable in case we sell all units">help_outlline</i></th>
                        <th>Exit Load (Rs)</th>
                        <th>Units (With Exit Load)</th>
                        </thead>
                        <tbody>
                        <tr *ngFor = "let holding of sipHoldings; let i = index" >
                          <td  title="{{holding.securityDescription}}"class="doc-name-col" >{{holding.securityDescription}}</td>
                          <td>{{holding.closingBalance | number:'.2-2'}}</td>
                          <td>{{holding.closingNetAmount | number:'.2-2'}}</td>
                          <td>{{holding.annualizedReturn_3years  | number:'.2-2'}}</td>
                          <td>{{holding.totalTax  | number:'.2-2'}}</td>
                          <td>{{holding.exitLoadApplicable  | number:'.2-2'}}</td>
                          <td>{{holding.unitsUnsold  | number:'.2-2'}}</td>

                        </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
            </div>

        </div>

        <span *ngIf="equityHolding?.length>0" class="Equity-Allocation">Equity Allocation</span>

        <div *ngIf="equityHolding?.length>0" class="tab-3" id ="equity-tab">
            <div class="Inner-tab">
                <div class="Progress-bar-1">
                      <div echarts class="Progress-bar"  [options]="pieEquityChartOptions" [autoResize]="true"     ></div>
                    <div class="box-3">
                      <div class="box-lower-container">
                        <div class="box-lower-container-inner">
                        <div class="box-lower-left">Current Valuation</div>
                        <div class="box-lower-right" title="{{pieChartData.EQUITY?.closingNetAmount  | number:'.2-2'}}"  >{{pieChartData.EQUITY?.closingNetAmount  | shortNumber}}</div>
                      </div>
                        <div class="box-lower-container-inner">

                        <div class="box-lower-left">Active Schemes (No.)</div>
                        <div class="box-lower-right">{{pieChartData.EQUITY?.totalActiveSchemes}}</div>
                      </div>
                        <div class="box-lower-container-inner">

                        <div class="box-lower-left">IRR (%)</div>
                          <div class="box-lower-right" *ngIf="pieChartData.EQUITY?.annualizedReturn" >{{pieChartData.EQUITY?.annualizedReturn  | number:'.2-2'}}</div>
                          <div class="box-lower-right" *ngIf="!pieChartData.EQUITY?.annualizedReturn" >N.A.</div>

                        </div>
                      </div>

                    </div>
                </div>
                <div id="myModal" role="dialog" class="popup-table">
                    <div id="modal-content-debt">
                        <table cellpadding="1" >
                            <thead style="text-decoration: none;">
                                <th>Transaction Date</th>
                                <th>Transaction Details</th>
                                <th>Units</th>
                                <th>Price</th>
                                <th>Amount (Rs)</th>
                            </thead>
                            <tbody>
                            <tr *ngFor = "let transaction of equityTransactions; let i = index" >
                              <td>{{transaction.date}}</td>
                              <td>{{transaction.transactionDetails}}</td>
                              <td>{{transaction.units}}</td>
                              <td>{{transaction.price | number:'.2-2'}}</td>
                              <td>{{transaction.amount| number:'.2-2'}}</td>

                            </tr>


                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="table-1 " id ="equity-tab-table" #childElement>
                    <div style="margin-left: 11px; margin-top: 0px; ">
                        <table cellpadding="1 ">
                            <thead style="text-decoration: none; ">
                                <th>S. No.</th>
                                <th >Scheme Name</th>
                                <th>Total Units</th>
                                <th>Current Value (Rs)</th>
                                <th>3 Year IRR (%)</th>
                                <th>Tax Applicable (Rs) <i class="material-icons help-outline" style="color:black;" title="Tax payable in case we sell all units">help_outlline</i></th>
                                <th>Exit Load (Rs)</th>
                                <th>Units (With Exit Load)</th>
                            </thead>
                            <tbody>

                                <tr *ngFor = "let holding of equityHolding;let i = index" data-toggle="modal" (click)="getTransactions(holding,'equity')" data-target="#myModal" >
                                    <td>{{i+1}}</td>
                                    <td  title="{{holding.securityDescription}}"class="doc-name-col" >{{holding.securityDescription}}</td>
                                    <td>{{holding.closingBalance | number:'.2-2'}}</td>
                                    <td>{{holding.closingNetAmount | number:'.2-2'}}</td>
                                    <td>{{holding.annualizedReturn_3years  | number:'.2-2'}}</td>
                                    <td>{{holding.totalTax  | number:'.2-2'}}</td>
                                    <td>{{holding.exitLoadApplicable  | number:'.2-2'}}</td>
                                    <td>{{holding.unitsUnsold  | number:'.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>



        </div>

        <span *ngIf="debtHolding?.length>0" class="Debt-Allocation">Debt Allocation</span>

        <div *ngIf="debtHolding?.length>0" class="tab-4" id ="debt-tab">

            <div class="Inner-tab">
                <div class="Progress-bar-1">
                  <div echarts class="Progress-bar"   [options]="pieDebtChartOptions" [autoResize]="true"     ></div>

                  <div class="box-3">
                    <div class="box-lower-container">
                      <div class="box-lower-container-inner">
                        <div class="box-lower-left">Current Valuation</div>
                        <div class="box-lower-right"  title="{{pieChartData.DEBT?.closingNetAmount  | number:'.2-2'}}" >{{pieChartData.DEBT?.closingNetAmount  | shortNumber}}</div>
                      </div>
                      <div class="box-lower-container-inner">

                        <div class="box-lower-left">Active Schemes (No.)</div>
                        <div class="box-lower-right">{{pieChartData.DEBT?.totalActiveSchemes}}</div>
                      </div>
                      <div class="box-lower-container-inner">

                        <div class="box-lower-left">IRR (%)</div>
                        <div class="box-lower-right" *ngIf="pieChartData.DEBT?.annualizedReturn" >{{pieChartData.DEBT?.annualizedReturn  | number:'.2-2'}}</div>
                        <div class="box-lower-right" *ngIf="!pieChartData.DEBT?.annualizedReturn" >N.A.</div>

                      </div>
                    </div>

                  </div>
                </div>
              <div id="debt-transactions" role="dialog" class="popup-table">
                <div id="modal-content">
                  <table cellpadding="1" id="myTable">
                    <thead style="text-decoration: none;">
                    <th>Transaction Date</th>
                    <th>Transaction Details</th>
                    <th>Units</th>
                    <th>Price (Rs)</th>
                    <th>Amount (Rs)</th>
                    </thead>
                    <tbody>
                    <tr *ngFor = "let transaction of debtTransactions; let i = index" >
                      <td>{{transaction.date}}</td>
                      <td>{{transaction.transactionDetails}}</td>
                      <td>{{transaction.units}}</td>
                      <td>{{transaction.price | number:'.2-2'}}</td>
                      <td>{{transaction.amount| number:'.2-2'}}</td>

                    </tr>


                    </tbody>
                  </table>
                </div>
              </div>

                <div class="table-2" id ="debt-tab-table">
                    <div style="margin-left: 11px; margin-top: 0px;">
                        <table cellpadding="1">
                            <thead style="text-decoration: none;">
                            <th>S. No.</th>
                            <th>Scheme Name</th>
                            <th>Total Units</th>
                            <th>Current Value (Rs)</th>
                            <th>3 Year IRR (%)</th>
                            <th>Tax Applicable (Rs) <i class="material-icons help-outline" style="color:black;" title="Tax payable in case we sell all units">help_outlline</i></th>
                            <th>Exit Load (Rs)</th>
                            <th>Units (With Exit Load)</th>
                            </thead>
                                <tr *ngFor = "let holding of debtHolding; let i = index" data-toggle="modal" (click)="getTransactions(holding,'debt')" data-target="#debt-transactions">
                                  <td>{{i+1}}</td>
                                  <td title="{{holding.securityDescription}}"class="doc-name-col">{{holding.securityDescription}}</td>
                                  <td>{{holding.closingBalance | number:'.2-2'}}</td>
                                  <td>{{holding.closingNetAmount | number:'.2-2'}}</td>
                                  <td>{{holding.annualizedReturn_3years  | number:'.2-2'}}</td>
                                  <td>{{holding.totalTax  | number:'.2-2'}}</td>
                                  <td>{{holding.exitLoadApplicable  | number:'.2-2'}}</td>
                                  <td>{{holding.unitsUnsold  | number:'.2-2'}}</td>
                                </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>


        <span *ngIf="uncategorizedHolding?.length>0" class="Debt-Allocation">Uncategorized Allocation</span>

        <div *ngIf="uncategorizedHolding?.length>0" id ="uncategorized-tab"class="tab-4">

            <div class="Inner-tab">
                <div class="Progress-bar-1">
                  <div echarts class="Progress-bar"   [options]="pieUncategorizedChartOptions" [autoResize]="true"  ></div>

                  <div class="box-3">
                    <div class="box-lower-container">
                      <div class="box-lower-container-inner">
                        <div class="box-lower-left">Current Valuation</div>
                        <div class="box-lower-right" title="{{pieChartData.UNCATEGORIZED?.closingNetAmount  | number:'.2-2'}}" >{{pieChartData.UNCATEGORIZED?.closingNetAmount  | shortNumber}}</div>
                      </div>
                      <div class="box-lower-container-inner">

                        <div class="box-lower-left">Active Schemes (No.)</div>
                        <div class="box-lower-right">{{pieChartData.UNCATEGORIZED?.totalActiveSchemes}}</div>
                      </div>
                      <div class="box-lower-container-inner">

                        <div class="box-lower-left">IRR (%)</div>
                        <div class="box-lower-right" *ngIf="pieChartData.UNCATEGORIZED?.annualizedReturn" >{{pieChartData.UNCATEGORIZED?.annualizedReturn  | number:'.2-2'}}</div>
                        <div class="box-lower-right" *ngIf="!pieChartData.UNCATEGORIZED?.annualizedReturn" >N.A.</div>
                      </div>
                    </div>

                  </div>
                </div>
              <div id="uncategorized-transactions" role="dialog" class="popup-table">
                <div id="uncategorized-modal-content">
                  <table cellpadding="1" id="uncategorized-myTable">
                    <thead style="text-decoration: none;">
                    <th>Transaction Date</th>
                    <th>Transaction Details</th>
                    <th>Units</th>
                    <th>Price (Rs)</th>
                    <th>Amount (Rs)</th>
                    </thead>
                    <tbody>
                    <tr *ngFor = "let transaction of uncategorizedTransactions; let i = index" >
                      <td>{{transaction.date}}</td>
                      <td>{{transaction.transactionDetails}}</td>
                      <td>{{transaction.units}}</td>
                      <td>{{transaction.price | number:'.2-2'}}</td>
                      <td>{{transaction.amount| number:'.2-2'}}</td>

                    </tr>


                    </tbody>
                  </table>
                </div>
              </div>

                <div class="table-2" id ="uncategorized-tab-table">
                    <div style="margin-left: 11px; margin-top: 0px;">
                        <table cellpadding="1">
                            <thead style="text-decoration: none;">
                            <th>S. No.</th>
                            <th class="name_doc">Scheme Name</th>
                            <th>Total Units</th>
                            <th>Current Value (Rs)</th>
                            <th>3 Year IRR (%)</th>
                            <th>Tax Applicable (Rs)</th>
                            <th>Exit Load (Rs)</th>
                            <th>Units (With Exit Load)</th>
                            </thead>
                                <tr *ngFor = "let holding of uncategorizedHolding; let i = index" data-toggle="modal" (click)="getTransactions(holding,'uncategorized')" data-target="#uncategorized-transactions">
                                  <td>{{i+1}}</td>
                                  <td  title="{{holding.securityDescription}}"class="doc-name-col" >{{holding.securityDescription}}</td>
                                  <td>{{holding.closingBalance | number:'.2-2'}}</td>
                                  <td>{{holding.closingNetAmount | number:'.2-2'}}</td>
                                  <td>{{holding.annualizedReturn_3years  | number:'.2-2'}}</td>
                                  <td>{{holding.totalTax  | number:'.2-2'}}</td>
                                  <td>{{holding.exitLoadApplicable  | number:'.2-2'}}</td>
                                  <td>{{holding.unitsUnsold  | number:'.2-2'}}</td>
                                </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>



    </div>
</div>

<div *ngIf="blurPage" class="psuedo-body-box">

  <app-spinner class="spinner" [showSpinner]=true></app-spinner>

</div>
