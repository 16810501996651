<div class="main_left_container">
  <div class="login_form">
      <div class="both">
        <div class="tab">
          <button class="tablink1" (click)="gotoProfile()">Profile</button>
        </div>
        <div class="tab2">
          <button class="tablink2" (click)="gotoChangePassword()">Password</button>
        </div>
      </div>
    <form  [formGroup]= "changePassForm"  class="ChangePassword-form" (ngSubmit)="changePassword()">
      <div class="input">
        <div class="form-group" >
        <div class="input_text"><span>Old Password</span></div>
        <input type="Password" formControlName="oldPassword" name="oldPassword"  required class="loginForm_input"  id="login-oldpassword" placeholder="Old Password">
          <div *ngIf="submitted && oldPassword.errors" class="errorMessage">
            <span *ngIf="oldPassword.hasError('required')">Password is required.</span>
            <span *ngIf="oldPassword.hasError('maxlength')">Only 10 characters allowed for password.</span>
          </div>
        </div>

        <div class="form-group" >
        <div class="input_text"><span>New Password</span></div>
        <input type="Password" required class="loginForm_input" formControlName="password" name="password"  id="login-Newpassword" placeholder="New Password">
          <div *ngIf="submitted && password.errors" class="errorMessage">
          <span *ngIf="password.hasError('required')">Password is required.</span>
          <span *ngIf="password.hasError('maxlength')">Only 10 characters allowed for password.</span>
            <span *ngIf="password.hasError('pattern')">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character.</span>
          </div>
        </div>

          <div class="form-group">
        <div class="input_text"><span>Confirm Password</span></div>
        <input type="Password" required class="loginForm_input" formControlName="confirm_password" name="confirm_password" id="login-Confirmpassword" placeholder="Confirm Password">
            <div *ngIf="submitted && confirm_password.errors" class="errorMessage">
              <span *ngIf="confirm_password.hasError('required')">Confirm Password is required.</span>
              <span *ngIf="confirm_password.hasError('mustMatch')">Password Must Match.</span>
          </div>
      </div>
      <div class="button">
        <button class="cancel" (click)="gotoProfile()">CANCEL</button>
        <div class="form-group">
        <button (click)="changePassword()" [disabled]="loading" class="save">SAVE</button>
        </div>
      </div>
      </div>
    </form>
  </div>

</div>
