
<div class="main_text">
    <div class="logo">
      <img class="Path" src="assets/img/MFIT_final.png">
    </div>
    <div class="logo_text_container">
    <span>MFIT TECHNOLOGIES
    </span>
    </div>
   </div>
  <div class="main">
        <div class="head_text">
                  <span>Reset <br>Password</span>
                 </div>
              <form #heroForm="ngForm" novalidate>
               <div class="input">
                  <div class="input_text" [class.has.error]="password.invalid && password.touched"><span>New Password</span></div>
                  <input type="Password" required class="loginForm_input" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" #password="ngModel" id = "login-password"
                  placeholder="Password"  name="password"ngModel>
                 <br>
                  <div style="text-align:left ;margin-left:12%" *ngIf="password.errors &&  submitted">
                    <span class="text_danger" [hidden]="!password.errors.required">Password is required </span>
                    <span class="text_danger"[hidden]="!password.errors.pattern">Password must contain at least one number and one uppercase and lowercase letter,and at least 8 or more characters. </span>
                    </div>


                  <div class="input_text"[class.has.error]="password.invalid && password.touched" ><span>Confirm Password</span></div>
                  <input type="Password" class="loginForm_input" #confirmpassword="ngModel" id="login-confirm-password"
                  placeholder=" Confirm Password"required pattern="{{password.value}}" name="confirmPassword"ngModel><br>
                  <div *ngIf="password.errors && submitted"style="text-align:left ;margin-left:12%">
                    <span class="text_danger" [hidden]="!password.errors.required">confirm Password is required </span>

                 <span class="text_danger" *ngIf = "submitted && !passwordMatch" >Password and Confirm Password does not match.</span>
                 </div>
                <button class="Confirm" (click)="onSubmit(heroForm)">CONFIRM</button>




</div>

              </form>
  </div>
