<div class="main_left_container">
    <div class="main-container">
        <div class="header_tag">
            <div class="main-tab">
                <div class="launch-pricing-tab">
                    <span class="launch-pricing-text">LAUNCH PRICING</span><br/>
                    <span class="free-text">FREE</span><br>
                    <span class="covers-your-basic-ne-text">Covers your basic needs</span>
                    <button class="your-plan-btn">YOUR PLAN</button>
                </div>

                <div class="Professional-tab">
                    <span class="professional-text">PROFESSIONAL</span>
                    <div class="inner-tab">
                        <span class="rs-500-month-text">Rs 500/month</span>
                    </div>
                    <div>
                        <span class="or-rs-5000-year-2-text"> or Rs 5000/Year*<br> (2
                            months free when billed yearly)
                        </span>
                    </div>
                    <button class="buy-plan-btn">BUY PLAN</button>
                </div>

                <div class="Enterprise-tab">
                    <span class="enterprise-text">ENTERPRISE</span>
                    <div class="">
                        <span class="lets-discuss-text">Let's Discuss</span>
                    </div>
                    <div>
                        <span class="reach-out-at-enterpr-text">reach out at
                            enterprise@mfit.co.in </span>
                    </div>
                    <button class="your-plan-btn">BUY PLAN</button>
                </div>
            </div>
        </div>
        <div class="table-container">
            <div class="table-1">
                <table>
                    <tr>
                        <th>Data Extraction</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>No of User</td>
                        <td> 1</td>
                        <td> 1</td>
                        <td> Unlimited</td>
                    </tr>
                    <tr>
                        <td>No of Documents</td>
                        <td>Upto10/ month</td>
                        <td>Unlmited</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <td>Investments PDFs</td>
                        <td>All Investments PDFs</td>
                        <td>All Investments PDFs</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Excels Reports </td>
                        <td>-</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Image Scans</td>
                        <td>-</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Bank PDFs</td>
                        <td>-</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                </table>
            </div>
            <div class="table-2">
                <table>
                    <tr>
                        <th>Enrichment</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Consolidation</td>
                        <td>-</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>MFIT Output</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Morning Star output</td>
                        <td>-</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Dasboard View </td>
                        <td>-</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Custom Output </td>
                        <td>-</td>
                        <td>-</td>
                        <td>Yes</td>
                    </tr>
                </table>
            </div>

            <div class="table-3">
                <table>
                    <tr>
                        <th>Analytics</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>IRR Calculation</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Exit Loads</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Tax calculations</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Asset Allocation</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Custom Analytics</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Yes</td>
                    </tr>
                </table>
            </div>

            <div class="table-4">
                <table>
                    <tr>
                        <th>Analytics</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>API Integration</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Dashboard Widgets</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Yes</td>
                    </tr>
                </table>
            </div>
            <div class="warning_tab">
                <div class="warning">
                    <span class="warning-msg">*Refund Policy : no questions asked - money returned on prorata monthly cycle
                        basis whenenver plan is cancelled.</span>
                </div>
            </div>
        </div>
    </div>
</div>
