<div class="middle_container" >
        <form  (ngSubmit)=uploadFile($event)>

        <div class="recent_title">
            <span class="txt">Upload Master</span>
        </div>
        
        <input type="file" class="file-input" (change)="onSelectFile($event)" #fileUpload>

        <div class="rectangle_box">
            
            <select name="uploadType" class="dropdownBox" (change)="selectUploadType($event.target.value)"  id="uploadType">
                <option value="E">Please select one</option>
                <option value="SCHEME MASTER">SCHEME MASTER UPLOAD</option>
                <option value="EXIT LOAD MASTER">EXIT LOAD MASTER UPLOAD</option>
                <option value="ARN MASTER">ARN MASTER UPLOAD</option>
                <option value="AR RECOMMENDATION MASTER">AR RECOMMENDATION MASTER UPLOAD</option>
            </select>   
            

            <span class="fileText">{{fileName || "No file selected."}}</span>
            <br><br>

            
            <button type="button" class="add-file-btn" (click)="fileUpload.click()">
                {{ uploadButtonText }}
            </button>     
            <button [disabled]="" class="extract-file-btn">SUBMIT</button>         
    </div>

    <div class="statusText">
        <span>{{ status }}</span>
    </div>

</form>
</div>