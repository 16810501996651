<!--
<head>
<link rel="stylesheet" href="app.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
</head>

-->

<div class="page">
<div class="header"  *ngIf="showSideBar">

  <div class="Shape">
      <i class="material-icons menu_icon" (click)="toggleMenu()" *ngIf="showSideBar">{{menuIcon}}</i>
  </div>

  <div class="company-icon">
      <div style="width: 100%;">
          <img class="Path" alt="mfit-logo" src="assets/img/MFIT_final.png">
          <div class="MFITTECHNOLOGIES"> MFIT TECHNOLOGIES </div>

          <div style="float: right;">
              <img alt="" class="XMLID_115_" src="assets/img/vector-coins-6.png">

              <div class="conversion-left">Unlimited conversion left</div>

<!--              <div class="Rectangle-Copy">-->
<!--                  <i class="material-icons notification_icon" (click)="onClickNotifications()" >notifications</i>-->
<!--                  <span class="Oval">3</span>-->
<!--              </div>-->

              <div class="tooltip">
                <div class="Rectangle-profile">
                  <i class="material-icons profile-img"  style="font-size:24px; color:#fff; text-align:center;margin-left: 10px!important; ">settings</i>
                   <a class="user_name">{{user.name}}</a>
                     <div class="triangle">
                         <i class="" style="background-color: black;"></i>
                         <div class="profile-down-arrow"></div>
                     </div>
                 </div>

                  <div class="tooltiptext"style="width: 253px;">
                      <div class="rectangle1">
                          <div class="icons-tab">
                            <i routerLink="/my-profile" class="material-icons dropdown_menu_icons">account_circle</i>
                          <!-- <i routerLink="/invoice"  class="material-icons dropdown_menu_icons">credit_card</i>-->
                            <i (click)="loggedOut()"  class="material-icons dropdown_menu_icons">exit_to_app</i>
                          </div>
                          <div class="profile-tab">
                            <a routerLink="/my-profile" class="my-profile">My Profile</a>
                         <!--  <a routerLink="/invoice" class="billing">Billing</a>-->
                            <a (click)="loggedOut()"  class="logout">Logout</a>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </div>

</div>

<app-sidebar [expand]="expandSideBar" *ngIf="showSideBar"></app-sidebar>
<app-notifications *ngIf="expandNotifications"></app-notifications>
</div>
<router-outlet [ngClass]="{
    'background-color:grey': expandSideBar,
    'background-color:none': !expandSideBar}"></router-outlet>
