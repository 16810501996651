import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import {ReactiveFormsModule} from "@angular/forms";
import {SearchService} from "./search.service";
import {PaginationComponent} from "../pagination/pagination.component";



@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    SearchComponent,

  ]
})
export class AutoCompleteModule { }
