<div class="main_left_container">
    <div class="middle_container">
        <div class="recent_title">
            <span class="txt">Gapsheet Files</span>
        </div>
      <button *ngIf="isFilterSet" type="button" class="button-clear" (click)="clearFilterFields()" >
        <div class="both_filter">
          <i class="material-icons md-clear-icon" >close</i> Clear</div></button>

        <button  [ngClass]="{'button-filter':toggle,'button-filter-enabled':!toggle}"(click)="filter()" >
          <div class="both_filter">
          <i class="material-icons md-filter_list"[ngClass]="{'md-filter_list':toggle,'md-filter_list-enabled':!toggle}" >filter_list</i> Filter</div></button>

    </div>

    <div class="lower_container">
        <table cellpadding="2">
            <tr>
                 <th>Document Name</th>
                <th>Password</th>
                <th>Type</th>
                <th>
                  <div class="table_header">
                    <span class="header_name">Upload Time</span>
                   </div>
              </th>
             <th>Action</th>
              </tr>

          <tr *ngFor="let file of allFiles">
                <td title="{{file.documentName}}" class="doc-name-col">{{file.documentName}}</td>
                <td>{{file.inputDocumentPassword}}</td>
                <td> {{file.documentFormat}}</td>
            <td>{{file.uploadTime|date}}<br><span class="time">{{file.uploadTime|date:'shortTime'}}</span></td>

                <td>
                  <i class="material-icons" (click)="goToDashboard(file)"  [ngClass]="{
                            'visibility-enabled': hasGapSheet(file),
                            'visibility-disabled':!hasGapSheet(file) }">visibility</i>            </tr>
        </table>
    </div>
  <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination>

</div>

<form [formGroup]="dateFilterForm"  id="filter-pop-up-tab" class="filter-pop-up-modal">
    <div class="filter-tooltiptext">
        <div class="filter-uploading-details">
            <div class="inner-tab">
                <span class="upload-timings">Upload Timings</span><br>
                <span class="select-start-date-an-text">Select start date and end date </span>
                 <br> <span *ngIf="submitted" class="errorMessage" >{{errorMsg}}</span>

            </div>
            <input class="start-date" formControlName="startDate"  type="text" id="start-date" name="startDate" onfocus="(this.type='date')" placeholder="Start Date">

            <input class="start-date" formControlName="endDate"  type="text" id="end-date" name="endDate" onfocus="(this.type='date' )" placeholder="End Date">

        </div>
        <div class="line"></div>

        <div class="filter-btn-tab ">
            <button (click)="clearFilterFields()" class="clear-filter " type="reset " value="Reset ">Clear Filter</button>

            <button class="cancel " (click)="closeFilter()"  type="button" >Cancel</button>

            <button class="apply" type="submit" (click)="onFilterSubmit()" >Apply</button>
        </div>
    </div>
  </form>
