<div  [ngClass]="{
    'box': expand,
    'smallbox': !expand
  }">
  
  <div class="sidebar_icons_container" (mouseenter)="openSideBar()" (mouseleave)="collapseSideBar()"*ngFor="let item of menuItems ; let i =index" (click)="onClick(item.link , i )">
    <div *ngIf="i==currentLinkNo" class="selected-main-box"  >   
      <div class="selected-side-box" ></div>
      <div class="sidebar-innercontainer-selected">
      <i class="material-icons sidebar-icons-selected"  >{{item.icon}} </i>
      <span [ngClass]="{
        'sidebar-text-big-selected': expand,
        'sidebar-text-small': !expand
      }">{{item.title}}</span>
    </div>
    
    </div>
    <div *ngIf="i!=currentLinkNo"class="sidebar-innercontainer">
      <div class="sidebar-innercontainer-unselected">
        <i class="material-icons sidebar-icons-unselected"  >{{item.icon}} </i>
        <span [ngClass]="{
          'sidebar-text-big-unselected': expand,
          'sidebar-text-small': !expand
        }">{{item.title}}</span>
      </div>
    </div>


  
    
  </div>
</div>
<div [style.display]=" expand? 'block':'none'" class="psuedo-body-box"></div>