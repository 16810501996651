import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent } from './user-management/user-management.component';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { LicenseManagementComponent } from './license-management/license-management.component';
import { PaginationModule } from '../pagination/pagination.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AutoCompleteModule} from "../auto-complete/auto-complete.module";
import {MatSelectModule} from "@angular/material/select";
import {SpinnerModule} from "../spinner/spinner.module";
import {DropdownModule} from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';


@NgModule({
  declarations: [LicenseManagementComponent, UserManagementComponent, CompanyManagementComponent],
    imports: [
        CommonModule,
        PaginationModule,
        FormsModule,
        SpinnerModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        OverlayPanelModule,
        MatSelectModule,
        MultiSelectModule,
        DropdownModule,

    ]
})
export class AdminModule { }
