import { Injectable } from '@angular/core';
import { CanActivate,
ActivatedRouteSnapshot,
RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CanActivateAdminFeaturesRouteGuard implements CanActivate {

  user:any;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
     this.user = localStorage.user? JSON.parse(localStorage.user):{};
      return this.user.roles === "admin" || this.user.roles === "super_admin";
  }
}
