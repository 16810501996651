import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {SharedService} from "../../shared.service";
import {BaseService} from "../../base.service";
import {environment} from "../../../environments/environment";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  title = 'dashboard';
  changePassForm: FormGroup;
  submitted = false;
  successMsg = false;
  erroMsg = false;
  oldPassword:FormControl;
  password: FormControl;
  confirm_password: FormControl;
  erroMsgText: string;
  loading = false;
  successModalMsg: string;
  errorModalMsg: string;


  constructor(
    private router: Router,
    private sharedService: SharedService,
    private baseService: BaseService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.oldPassword=new FormControl('', [Validators.required, Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')]);
    this.password = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')]);
    this.confirm_password = new FormControl('',[Validators.required, this.MustMatch(this.password)]);
    this.changePassForm = this.formBuilder.group(
      {
        'oldPassword': this.oldPassword,
        'password': this.password,
        'confirm_password': this.confirm_password,


      });


  }
  MustMatch(passwordControl : AbstractControl) : ValidatorFn
  {
    return (cpasswordControl : AbstractControl) : {[key: string] : boolean } | null   =>
    {
      // return null if controls haven't initialised yet
      if(!passwordControl && !cpasswordControl)
      {
        return null;
      }

      // return null if another validator has already found an error on the matchingControl
      if (cpasswordControl.hasError && !passwordControl.hasError)
      {
        return null;
      }
      // set error on matchingControl if validation fails
      if(passwordControl.value !== cpasswordControl.value)
      {
        return { 'mustMatch': true };
      }
      else {
        return null;
      }

    }


  }
  // get f() { return this.changePassForm.controls; }

  changePassword() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.changePassForm.invalid) {
      this.loading = false;
      return;
    } else {
      const formObject = this.changePassForm.value;
      // delete termsAndConditions from Form object
      delete formObject.confirm_password;

      let changePassword = {};
      changePassword["oldPassword"] = this.changePassForm.get("oldPassword").value;
      changePassword["newPassword"] = this.changePassForm.get("password").value;
      const path = environment.restPath + 'user/changePassword';
      this.baseService
        .save(path,changePassword)
        .subscribe(
          res => {
            if (res['statusCode'] == 200) {
              // this.successMsg = true;
              this.erroMsg = false;
              this.submitted = false;
              this.changePassForm.reset();
              this.loading = false;
              this.successModalMsg = 'Your password has been changed successfully.';
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              document.cookie = 'token' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              window.location.pathname = '/login.html';
            } else {
              this.successMsg = false;
              this.erroMsg = true;
              this.loading = false;
              if (res['statusCode'] == 404) {
                this.erroMsgText = 'You already changed your password.';
              } else if (res['statusCode'] == 401) {
                this.erroMsgText = 'Your old password is not correct.';
              } else if (res['statusCode'] == 403) {
                this.erroMsgText = 'You already changed your password.';
              } else {
                this.erroMsgText = 'Opps! Something went wrong.';
              }
            }
            // this.goBack();
          },
          error => {
            this.successMsg = false;
            this.erroMsg = true;
            this.loading = false;
            this.erroMsgText = 'Opps! Server is not running.';
          });
    }
    this.toastr.success('Your Password Has Been Changed Successfully!','',{
      timeOut: 4000
     });
  }

  gotoProfile() {
    this.router.navigate(['/my-profile']);
  }

  gotoChangePassword() {
    this.router.navigate(['/changePassword']);
  }
}
