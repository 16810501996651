import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ApprovedFilesComponent } from './approved-files/approved-files.component';
import { YetToBeApprovedFilesComponent } from './yet-to-be-approved-files/yet-to-be-approved-files.component';
import { UsageStatisticsComponent } from './usage-statistics/usage-statistics.component';
import { SplitFilesComponent } from './split-files/split-files.component';
import { UploadOcrComponent } from './upload-ocr/upload-ocr.component';
import { PaginationModule } from '../pagination/pagination.module';
import { DndDirective } from './dnd.directive';
import{APIComponent}from'./api/api.component';
import  {  FormsModule,  ReactiveFormsModule  }  from  '@angular/forms';
import {SpinnerModule} from "../spinner/spinner.module";
import { UploadMasterComponent } from './upload-master/upload-master.component';


@NgModule({
  declarations: [UploadFileComponent, ApprovedFilesComponent,
    YetToBeApprovedFilesComponent, UsageStatisticsComponent,
    SplitFilesComponent, UploadOcrComponent,
    DndDirective,APIComponent, UploadMasterComponent,

  ],
    imports: [
        CommonModule,
        PaginationModule,
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule

    ]
})
export class UploadsModule { }
