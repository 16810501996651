import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { BaseService } from 'src/app/base.service';
import { FormBuilder, FormControl,AbstractControl, FormGroup, Validators ,ValidatorFn} from '@angular/forms';
import {environment} from "../../../environments/environment";
import {ToastrService} from "ngx-toastr";




@Component({
  selector: 'app-sign-up-component',
  templateUrl: './sign-up-component.component.html',
  styleUrls: ['./sign-up-component.component.css']
})
export class SignUpComponentComponent implements OnInit {

  constructor(
    private router:Router,
    private sharedService:SharedService,
    private baseService:BaseService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    )
  { }

  valueform: FormGroup;
  companyform: FormGroup;
  name: FormControl;
  password: FormControl;
  cpassword: FormControl;
  email: FormControl;
  phone: FormControl;
  Cemail: FormControl;
  Cphone: FormControl;
  Cname: FormControl;
  submitted = false;
  toggle =false;
  loading = false;
  errorList: string[];
  modalMessage : string;
  showcontent:boolean=true;

  test: any[] = [];


  onSubmit() {
    let signUpDTO = {};
    signUpDTO["name"] = this.valueform.get("name").value;
    signUpDTO["email"] = this.valueform.get("email").value;
    signUpDTO["password"] = this.valueform.get("password").value;
    signUpDTO["phoneNumber"] = this.valueform.get("phone").value;
    const path = environment.restPath + 'user/signUp';

    this.submitted = true;
    this.loading = true;
    this.toggle = false;

    // stop here if form is invalid
    if (this.valueform.invalid) {
      this.loading = false;
    }
    else
      {
      this.baseService.save(path, signUpDTO).subscribe(result => {
        this.loading = false;
        this.toastr.success('Please Verify the link Sent To Your Registered Email','',{
          timeOut: 4000
        });
        this.router.navigate(['/login']);
      }, error => {
        this.toastr.error(error.error,'',{
          timeOut: 4000
        });
        console.log(error)
        this.modalMessage = "Your Registration Was Unsuccessful";
      });

    }
  }

  onCompanySubmit() {

    let companyDTO = {};
    companyDTO["name"] = this.companyform.get("Cname").value;
    companyDTO["email"] = this.companyform.get("Cemail").value;
    companyDTO["phoneNumber"] = this.companyform.get("Cphone").value;
    const path = environment.restPath + 'user/companySignUp';

    this.submitted = true;
    this.loading = true;
    this.toggle = false;
    // stop here if form is invalid
    if (this.companyform.invalid) {

      this.loading = false;
      return;
    }
    else
    {
      this.baseService.save(path, companyDTO).subscribe(result => {
        this.loading = false;
        this.toastr.success('Thanks For Showing Interest In Our Product,We Will Reach You out Soon','',{
          timeOut: 4000
        });
        this.router.navigate(['/login']);
      }, error => {

        this.errorList = [];
        this.loading = false;
        for (var i = 0; i < error.error.value.length; i++) {
          this.errorList.push(error.error.value[i]);
          //console.log(error.error.value[i]);
        }

        console.log(error)
        this.modalMessage = "Your Registration Was Unsuccessful";
      });

    }
  }
  // Custom Validator

  MustMatch(passwordControl : AbstractControl) : ValidatorFn
  {
    return (cpasswordControl : AbstractControl) : {[key: string] : boolean } | null   =>
    {
      // return null if controls haven't initialised yet
      if(!passwordControl && !cpasswordControl)
      {
        return null;
      }

      // return null if another validator has already found an error on the matchingControl
      if (cpasswordControl.hasError && !passwordControl.hasError)
      {
        return null;
      }
      // set error on matchingControl if validation fails
      if(passwordControl.value !== cpasswordControl.value)
      {
        return { 'mustMatch': true };
      }
      else {
        return null;
      }

    }


  }
  ngOnInit() {
    this.name = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5), Validators.pattern(/^[^`~!#$%\^&*()+={}|[\]\\:\\';"<>?,/]*$/)]);
    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.phone = new FormControl('', [Validators.required, Validators.pattern('[6-9]\\d{9}')]);
    this.password = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')]);
    this.cpassword = new FormControl('',[Validators.required, this.MustMatch(this.password)]);
    this.valueform = this.formBuilder.group(
      {
        'name': this.name,
        'email': this.email,
        'phone': this.phone,
        'password': this.password,
        'cpassword': this.cpassword,
      });

    this.Cname = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5), Validators.pattern(/^[^`~!#$%\^&*()+={}|[\]\\:\\';"<>?,/]*$/)]);
    this.Cemail = new FormControl('', [Validators.required, Validators.email]);
    this.Cphone = new FormControl('', [Validators.required, Validators.pattern('[6-9]\\d{9}')]);
    this.companyform = this.formBuilder.group(
      {
        'Cname': this.Cname,
        'Cemail': this.Cemail,
        'Cphone': this.Cphone,
      });
    this.errorList = [];

  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
  gotoLogin() {
    this.router.navigate(['/login']);
  }
  showContent(){
    this.showcontent=!this.showcontent;
    this.toggle = true;
  }


}
