import {Component, OnInit, NgModule, ViewChild, ElementRef} from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
import {CommonModule, DatePipe, formatDate, getLocaleDateFormat} from "@angular/common";
import { EChartOption } from 'echarts';
import {ActivatedRoute, Router} from "@angular/router";
import {NgxEchartsDirective, NgxEchartsModule} from "ngx-echarts";
import { graphic } from 'echarts';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router:Router,private datePipe: DatePipe,private baseService: BaseService, private sharedService: SharedService) {

  }
  @ViewChild('parentElement') parentElement:ElementRef;

  parentRouter;
  blurPage:boolean=false;
  parentRouterName;
  summaryData:any=[];
  lineChartData:any=[];
  pieChartData:any={};
  holdingData:any=[];
  equityHolding:any =[];
  debtHolding:any =[];
  uncategorizedHolding :any =[];
  holdingsWithoutAmfiCode :any;
  equityTransactions:any =[];
  debtTransactions:any =[];
  uncategorizedTransactions:any =[];
  isLineChartAvailable :boolean = false;
  sipHoldings:any =[]

  lineChartOptions: EChartOption;
  pieEquityChartOptions: EChartOption;
  pieDebtChartOptions: EChartOption;
  pieUncategorizedChartOptions: EChartOption;
  LineChartDateList:any=[];
  lineChartValueList:any=[];
  uploadDocument:any={};
  uploadId:any;
  riskLevel:any
  holdingtable=true;

  ngOnInit(): void {

    this.riskLevel ="N.A"

    this.blurPage=true;

      this.parentRouter = this.sharedService.getGapSheetOriginCall()
    this.parentRouterName = this.parentRouter.toString().includes('gapsheet')?'Gapsheet Files List':'Upload'
    if(this.parentRouter.toString().includes('usage')){
      this.parentRouterName ="All Files"
    }
    this.uploadDocument =JSON.parse(this.route.snapshot.queryParamMap.get('upload'))
    this.uploadId = this.uploadDocument.id
    this.getSummaryData();
    this.getHoldingData();
    this.getLineChartData();
    this.getPieChartData();
    this.isLineChartAvailable = false;

  }

  downloadOutput(){
   window.location.href = environment.restPath+'file/download/reports/'+  this.uploadDocument.outputDocumentLink;
  }

  downloadPDFReport(){
    document.getElementById('report-link').style.display ="block"
    document.getElementById('parent-path').style.display ="none"
    document.getElementById('download-btn-pdf').style.display ="none"
    document.getElementById('download-btn-xls').style.display ="none"
    var tab1 = document.getElementById("equity-tab");
    var tab2 = document.getElementById("debt-tab");
    var tab3 = document.getElementById("uncategorized-tab");
    var table1 = document.getElementById("equity-tab-table");
    var table2 = document.getElementById("debt-tab-table");
    var table3 = document.getElementById("uncategorized-tab-table");
  if (tab1)  tab1.style.height ='auto'
    if (tab2)  tab2.style.height ='auto'
    if (tab3)  tab3.style.height ='auto'
    if (tab1) {
      table1.style.height ='auto'
    }
    if (tab2) {
      table2.style.height ='auto'
    }
    if (tab3) {
      table3.style.height ='auto'
    }



    var imgData;
    let parent = this.parentElement.nativeElement;
    html2canvas(parent,{
      scrollX: 0,
      scrollY: 0
    }


    ).then(function(canvas) {
      document.getElementById('report-link').style.display ="none"
      document.getElementById('parent-path').style.display ="block"
      document.getElementById('download-btn-pdf').style.display ="inline-block"
      document.getElementById('download-btn-xls').style.display ="inline-block"
      if (tab1) tab1.style.height ='259px'
      if (tab2) tab2.style.height ='259px'
      if (tab3) tab3.style.height ='259px'
      if (tab1) {
        table1.style.height ='259px'
      }
      if (tab2) {
        table2.style.height ='259px'
      }
      if (tab3) {
        table3.style.height ='259px'
      }

      // imgData = canvas.toDataURL("image/png");
      //
      //
      // var doc = new jsPDF("p", "mm", "a4");
      // const imgProps= doc.getImageProperties(imgData);
      // var width = doc.internal.pageSize.getWidth();
      // const pdfHeight = (imgProps.height * width) / imgProps.width;
      // doc.addImage(imgData, 'png',0,0,width,pdfHeight);
      //
      //
      // doc.save('Report.pdf');

      var doc = new jsPDF('p', 'mm',"a4");

      var width = doc.internal.pageSize.getWidth();
      var imgData = canvas.toDataURL('image/png');
      const imgProps= doc.getImageProperties(imgData);
      var imgWidth = doc.internal.pageSize.getWidth()+16;
      var pageHeight = doc.internal.pageSize.getHeight();
      var imgHeight = (canvas.height * imgWidth / canvas.width);
      var heightLeft = imgHeight;
      var bottomMargin = heightLeft-pageHeight>0?2:0; // give some top padding to first page
      var leftPosition = -9
      var imageType ="PNG"
      doc.addImage(imgData, imageType, leftPosition, 0, imgWidth, imgHeight+5);
      heightLeft -= pageHeight;
      var position =0;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, imageType,leftPosition, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save( 'gapsheet.pdf');


    });
    this.blurPage=false;
  }

  generateLineChart(){


    this.LineChartDateList =  this.lineChartData.map(function (item) {

      return item['date'];
    });

   this.lineChartValueList =  this.lineChartData.map(function (item) {


     return item['valuation'];
   });
      this.lineChartOptions =   {
     tooltip : {
       trigger: 'axis',

             formatter: (function (value) {
               var data  =  value[0].data.toLocaleString('en-IN');

               return value[0].axisValue +'<br>'+ data
             }),

     },
     calculable : true,
     xAxis : [
       {
         inverse:true,
         type: 'category',
         boundaryGap:true,
         data:this.LineChartDateList,
         // axisLabel: {
         //          formatter: (function(value){
         //
         //
         //            return value.toString().substr(3);
         //         }),
         //       }

       }
     ],
     yAxis : [
       {
         name: 'Portfolio Value (INR)',
         type: 'value',
         scale: true,
         splitLine:false,
         data: this.lineChartValueList,


       axisLabel: {
                  formatter: (function(value){


                    return value.toLocaleString('en-IN');
                 }),
               }
       }
     ],
      dataZoom: [
        {
          title:'filter data',
          id: 'dataZoomX',
          type: 'slider',
          filterMode: 'filter',
          backgroundColor : '#1c2acc',

        },
        // {
        //   id: 'dataZoomY',
        //   type: 'slider',
        //   yAxisIndex: [0],
        //   filterMode: 'empty'
        // }
      ],
     series : [

       {
         type:'line',
         showSymbol: false,
         data: this.lineChartValueList,
         lineStyle: {color: '#1f35bd'},
         areaStyle: {
           color:  new graphic.LinearGradient(0, 0, 0, 1, [{
             offset: 0,
             color: '#1f35bd'
           }, {
             offset: 1,
             color: '#ffe'
           }])
         },

       }
     ]
   }
  }

  generatePieChart(){

    if(this.pieChartData.EQUITY){
     if(this.pieChartData.EQUITY.closingNetAmount/this.summaryData.closingNetAmount>=0.8){
       this.riskLevel ="High"
     }
     else if(this.pieChartData.EQUITY.closingNetAmount/this.summaryData.closingNetAmount<0.8){
      this.riskLevel ="Medium"
 
     }
    }
    if(!this.pieChartData.EQUITY && this.pieChartData.DEBT){
      this.riskLevel ="Medium"

    }


    this.pieEquityChartOptions = {

      legend: {
        orient: 'horizontal',
        data: ['EQUITY', 'OTHERS'],
        left:1
      },
      series: [
        {
          name: 'allocation',
          type: 'pie',
          radius: ['0%', '90%'],
          avoidLabelOverlap: false,
          zlevel:1,
          left:'10',
          top: '20',
          bottom: '10',

          label: {
            show: true,
            position: 'inner',
            formatter: '{d}%',
            fontSize:17,
            distanceToLabelLine: '1'

          },
          emphasis: {
            label: {
              show: true,
              fontSize: '17',
              fontWeight: 'bold'
            }
          },

          labelLine: {
            show: false,
            length2:2,
            length:2
          },
          data: [
            {value: this.pieChartData.EQUITY?.closingNetAmount, name: 'EQUITY', itemStyle:{color: '#03b5d0'}},
            {value: this.summaryData.closingNetAmount-this.pieChartData.EQUITY?.closingNetAmount, name: 'OTHERS',itemStyle:{color: '#d4d4d4'}},

          ]
        }
      ]
    };
    this.pieDebtChartOptions = {

      legend: {
        orient: 'horizontal',
        data: ['DEBT', 'OTHERS'],
        left:1


      },
      series: [
        {
          name: 'allocation',
          type: 'pie',
          radius: ['0%', '90%'],
          avoidLabelOverlap: false,
          zlevel:1,
          left:'10',
          top: '20',
          bottom: '10',
          label: {
            show: true,
            position: 'inner',
            formatter: '{d}%',
            fontSize:17,
            distanceToLabelLine: '1'

          },
          emphasis: {
            label: {
              show: true,
              fontSize: '17',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false,
            length2:2,
            length:2
          },
          data: [
            {value: this.pieChartData.DEBT?.closingNetAmount, name: 'DEBT', itemStyle:{color: '#03b5d0'}},
            {value: this.summaryData.closingNetAmount-this.pieChartData.DEBT?.closingNetAmount, name: 'OTHERS',itemStyle:{color: '#d4d4d4'}},

          ]
        }
      ]
    };


    this.pieUncategorizedChartOptions = {

      legend: {
        orient: 'horizontal',
        data: ['UNCATEGORIZED', 'OTHERS'],
        left:1


      },
      series: [
        {
          name: 'allocation',
          type: 'pie',
          radius: ['0%', '90%'],
          avoidLabelOverlap: false,
          zlevel:1,
          left:'10',
          top: '20',
          bottom: '10',
          label: {
            show: true,
            position: 'inner',
            formatter: '{d}%',
            fontSize:17,
            distanceToLabelLine: '1'

          },
          emphasis: {
            label: {
              show: true,
              fontSize: '17',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false,
            length2:2,
            length:2
          },
          data: [
            {value: this.pieChartData.UNCATEGORIZED?.closingNetAmount, name: 'UNCATEGORIZED', itemStyle:{color: '#03b5d0'}},
            {value: this.summaryData.closingNetAmount-this.pieChartData.UNCATEGORIZED?.closingNetAmount, name: 'OTHERS',itemStyle:{color: '#d4d4d4'}},

          ]
        }
      ]
    };

  }


  accumulatedHoldingsWithoutAmfiCodeName( arr:any=[]) {
    if(arr) {
      for (let i = 0; i < arr.length; i++) {
        if(this.holdingsWithoutAmfiCode){
          this.holdingsWithoutAmfiCode += ", " + arr[i]['securityDescription']

        }
        else {
          this.holdingsWithoutAmfiCode  = arr[i]['securityDescription']
        }
      }
    }
  }
  getSummaryData(){
    this.baseService.getData(environment.restPath+'gapsheet/summary/'+this.uploadId).subscribe(
      (data) =>
      {
        this.summaryData = data.result;
        console.log("summaryData",this.summaryData)

      }, (error)=>{
      });
  }
  getLineChartData(){
    this.baseService.getData(environment.restPath+'gapsheet/chart/line/'+this.uploadId).subscribe(
      (response) =>
      {
        if(response.status =='OK') {
          this.isLineChartAvailable = true
          this.lineChartData = response.result;
          if(!this.lineChartData || this.lineChartData.length==0) this.isLineChartAvailable = false
          this.generateLineChart();
        }

      }, (error)=>{
        console.log(error)
      });
  }
  getPieChartData(){
    this.baseService.getData(environment.restPath+'gapsheet/chart/pie/'+this.uploadId).subscribe(
      (response) =>
      {
        if(response.status =='OK') {
          this.pieChartData = response.result;
          this.generatePieChart();
          this.blurPage=false;

        }

      }, (error)=>{
        console.log(error)
      });
  }
  getHoldingData(){
    this.baseService.getData(environment.restPath+'gapsheet/holding/'+this.uploadId).subscribe(
      (data) =>
      {
        this.holdingData = data.result;
        this.equityHolding = this.holdingData.equityHolding;
        this.debtHolding = this.holdingData.debtHolding;
        this.uncategorizedHolding = this.holdingData.uncategorizedHolding;

        this.accumulatedHoldingsWithoutAmfiCodeName(this.holdingData['holdingsWithoutAmfiCode'])

        // this.equitySummary = this.holdingData.equitySummary;
        // this.debtSummary = this.holdingData.debtSummary;


      }, (error)=>{
      });
  }
  getSipHoldings(){

   this.sipHoldings =[]

    for (let holding of this.equityHolding){
      if (holding.hasOwnProperty('sipAmount')){

        this.sipHoldings.push(holding);
      }
    }
    for (let holding of this.debtHolding){
      if (holding.hasOwnProperty('sipAmount')){

        this.sipHoldings.push(holding);
      }
    }
    for (let holding of this.uncategorizedHolding){
      if (holding.hasOwnProperty('sipAmount')){

        this.sipHoldings.push(holding);
      }
    }
    this.holdingtable=!this.holdingtable;
   
    
  
  
  
  }
  getTransactions(holding,type){

    let holdingId = holding._id
    this.baseService.getData(environment.restPath+'gapsheet/transactions/'+this.uploadId+'/'+holdingId).subscribe(
      (data) =>
      {
        if(type=='equity')
        this.equityTransactions = data.result;
        else if(type=='debt')
        this.debtTransactions = data.result;
        else
        this.uncategorizedTransactions = data.result;

      }, (error)=>{
      });
  }
  downloadSourcePDF(){
    window.location.href = environment.restPath+'file/download/source/'+ this.uploadDocument.sourceDocumentLink;
  }
 
hold(){
  this.holdingtable=true;
}


}
