<div class="main_left_container">

    <div class="middle_container">
        <div class="recent_title">
            <span class="txt">All Files</span>
        </div>
        <select name="STATUS" (change)="selectStatus($event.target.value)"  id="status">
            <option value="E">ALL</option>
            <option value="REJECTED">ERROR</option>
            <option value="PENDING">READY</option>
          </select>
          
          <button *ngIf="isFilterSet" type="button" class="button-clear" (click)="clear()" >
            <div class="both_filter">
              <i class="material-icons md-clear-icon" >close</i> Clear</div></button>
    
            <button  [ngClass]="{'button-filter':toggle,'button-filter-enabled':!toggle}"(click)="filter()" >
              <div class="both_filter">
              <i class="material-icons md-filter_list"[ngClass]="{'md-filter_list':toggle,'md-filter_list-enabled':!toggle}" >filter_list</i> Filter</div></button>
        </div>
        <div class="lower_container">
        <table cellpadding="2">
            <tr>
                <th>
                    <div class="table_header">
                        <span class="header_name">Company Name</span>
                    </div>
                </th>
                <th>User Name</th>
                <th>Document Name</th>
                <th>Password</th>
                <th>Type</th>
                <th>
                    <div class="table_header">
                        <span class="header_name">Upload Time</span>
                    </div>
                </th>
                <th>Status</th>

                <th>Action</th>
            </tr>
            <tr *ngFor="let activity of allFiles">
                <td>{{activity.company?.displayName }}</td>
                <td class="user-name-col"title="{{activity.user?.name}}">{{activity.user?.name}}</td>
                <td title="{{activity.documentName}}" class="doc-name-col">{{activity.documentName}}</td>
                <td>{{activity.inputDocumentPassword}}</td>
                <td >{{activity.documentFormat}}</td>
                <td>{{activity.uploadTime|date}}<br><span class="time">{{activity.uploadTime|date:'shortTime'}}</span></td>
                <td *ngIf="activity.approvalStatus==='PENDING'" >
                    <button class="Status-READY"> 
                        READY
                     </button>
                </td>
                <td *ngIf="activity.approvalStatus==='REJECTED'">
                    <button class="Status-ERROR">
                        ERROR
                     </button>

                </td>
                <td *ngIf="activity.approvalStatus==='APPROVED'">
                    <button class="Status-VALIDATED">
                        VALIDATED
                     </button>
                    </td>


    

                <td>
                    <div class="Action">
                        <i class="material-icons md-get_app" (click)="downloadOutput(activity)">get_app</i>
                        <i class="material-icons md-visibility" (click)="goToDashboard(activity)"  [ngClass]="{
                              'visibility-enabled': hasGapSheet(activity),
                            'visibility-disabled':!hasGapSheet(activity) }">visibility</i>
                      <i class=" material-icons md-picture_as_pdf visibility-disabled " *ngIf="activity.sourceDocumentLink===''">picture_as_pdf</i>
                      <i class=" material-icons md-picture_as_pdf " *ngIf="activity.sourceDocumentLink!=''"  (click)="downloadPdf(activity) ">picture_as_pdf</i>                    </div>
                </td>
            </tr>
        </table>

    </div>
    <app-pagination [totalCount]="totalCount" (click)="reload()"></app-pagination>

</div>
<form [formGroup]="dateFilterForm"  id="filter-pop-up-tab" class="filter-pop-up-modal">
    <div class="filter-tooltiptext">
        <div class="filter-uploading-details">
            <div class="inner-tab">
                <span class="upload-timings">Upload Timings</span><br>
                <span class="select-start-date-an-text">Select start date and end date </span>
                 <br> <span *ngIf="submitted" class="errorMessage" >{{errorMsg}}</span>

            </div>
            <input class="start-date" formControlName="startDate"  type="text" id="start-date" name="startDate" onfocus="(this.type='date')" placeholder="Start Date">

            <input class="start-date" formControlName="endDate"  type="text" id="end-date" name="endDate" onfocus="(this.type='date' )" placeholder="End Date">

        </div>
        <div class="line"></div>

        <div class="filter-btn-tab ">
            <button (click)=" clearFilter()" class="clear-filter " type="reset " value="Reset ">Clear Filter</button>

            <button class="cancel " (click)=" cancelFilter()"  type="button" >Cancel</button>

            <button class="apply" type="submit" (click)="onFilterSubmit()">Apply</button>
        </div>
    </div>
  </form>

