import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GapsheetFileComponent } from './gapsheet-file/gapsheet-file.component';

import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import {PaginationModule} from "../pagination/pagination.module";
import {RouterModule} from "@angular/router";
import {AppModule} from "../app.module";
import {ShortNumberPipe} from "../shortNumberPipe";
import {SpinnerModule} from "../spinner/spinner.module";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [DashboardComponent, GapsheetFileComponent,ShortNumberPipe],
    imports: [
        CommonModule,
        PaginationModule,
        NgxEchartsModule.forRoot({
            echarts
        }),
        RouterModule,
        SpinnerModule,
        ReactiveFormsModule,

    ],
  providers: [
    DatePipe,
    ShortNumberPipe
]
})
export class DashboardModule { }
