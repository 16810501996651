import { Component, OnInit, ViewChild ,Pipe,PipeTransform} from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import {PaginationComponent} from "../../pagination/pagination.component";
import {PaginationModule} from "../../pagination/pagination.module";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.css']
})

export class APIComponent implements OnInit {

  constructor(private baseService:BaseService,private router:Router,private sharedService:SharedService,private fb:FormBuilder,private toastr: ToastrService) { }
  urlLink : String ;
  uploads:any;
  totalCount : number;
  notApprovedPageNum=1;
  errorMsg:string ="";
  submitted:boolean = false;
  isFilterSet :boolean = false;
  upload:any;
 toggle=true;

 documentToDelete : any;

 documentToDeleteName : string="";
  user :any;
  spinner = false;
  dateFilterForm: FormGroup;
  isSuperAdminUser = false
  status ="E";
  @ViewChild(PaginationComponent ) page_comp: PaginationComponent ;

  ngOnInit(): void {
    this.user = localStorage.user? JSON.parse(localStorage.user):{};
    this.isSuperAdminUser = this.user.roles === "super_admin";
    this.getUploads(this.sharedService.getPageNumber());
    this.dateFilterForm = this.fb.group({
      startDate:new FormControl('',Validators.required),
      endDate:new FormControl('',Validators.required),
    });
  }

  getUploads(page)
  {
    this.urlLink = 'apiFailure/list/page/'+page+ '?status='+this.status;
    this.baseService.getData(environment.restPath+this.urlLink).subscribe(
      (data) => {
           this.uploads = data.result.list;
           this.totalCount = data.result.totalCount;
       });
  }
  delete( ) {
    this.baseService.delete(environment.restPath+'apiFailure', this.documentToDelete.id).subscribe(
      (data) => {



        this.reload();
        this.toastr.success('Document Deleted  Successfully','',{
          positionClass:"toast-center-center",  timeOut: 4000
        });
        this.documentToDelete.clear();



      })

  }
  reload(){
    if(!this.isFilterSet)
      this.getUploads(this.sharedService.getPageNumber());
    else
      this.getFilteredFiles(this.sharedService.getPageNumber());

  }
    downloadOutput(upload){
      if(upload.outputDocumentLink && upload.outputDocumentLink.trim().length>0)
        window.location.href = environment.restPath+'apiFailure/download/reports/'+ upload.outputDocumentLink;
    }


    downloadpdf(upload){
      window.location.href = environment.restPath+'apiFailure/download/source/'+ upload.sourceDocumentLink;
    }
    fileToDelete(document){
      this.documentToDelete = document;
      this.documentToDeleteName = document.documentName;

    }
    onFilterSubmit(){
      this.submitted = true
      this.errorMsg="";
      let startDate =new Date( this.dateFilterForm.controls.startDate.value);
      let endDate = new Date( this.dateFilterForm.controls.endDate.value);

      if(this.dateFilterForm.invalid && this.submitted){
        this.errorMsg ="Please enter date"
      }
      else if(endDate.getTime() - startDate.getTime()<0){
        this.errorMsg="Invalid End Date"
      }
      else {
        this.sharedService.setPageNumber(1)
        this.getFilteredFiles(this.sharedService.getPageNumber())
        this.page_comp.ngOnInit()
        this.toggle=false;
        document.getElementById( 'filter-pop-up-tab').style.display='none'


      }
    }

    getFilteredFiles(page){
      this.urlLink = 'filter/notapproved/list/'+page+'/startDate/'+this.dateFilterForm.controls.startDate.value+'/endDate/'+this.dateFilterForm.controls.endDate.value;

      this.baseService.getData(environment.restPath+this.urlLink).subscribe(
        (data) => {
          this.uploads = data.result.list;
          this.totalCount = data.result.totalCount;
          this.isFilterSet = true;
        });
    }
    cancelFilter(){
      this.isFilterSet = false;
    this.sharedService.setPageNumber(1);
    this.reload()
    this.toggle=true;
    this.page_comp.ngOnInit()
    document.getElementById( 'filter-pop-up-tab').style.display='none'
    }
    clearFilter(){
      this.submitted=false;

      this.dateFilterForm.controls.startDate.reset();
      this.dateFilterForm.controls.endDate.reset();

    }
    clear(){
      this.isFilterSet = false;
    this.reload();
    this.toggle=true;
      this.dateFilterForm.controls.startDate.reset();
      this.dateFilterForm.controls.endDate.reset();

    }
    filter(){
      this.toggle=!this.toggle;
      document.getElementById('filter-pop-up-tab').style.display='block'
    }


    selectStatus(status:any):void{
      this.status = status
      this.reload()
    }

    reparseAllFiles(){
      const upload ="";
      let alert = confirm("Confirm!");
      if (alert && this.isSuperAdminUser) {
        this.spinner = true;

          this.baseService.save(environment.restPath+'apiFailure/reparseAll',upload).subscribe(
            (data)=>{
            this.spinner = false;
            this.reload();

          },
          (error) =>{
            this.spinner = false;
          });
      }
    }

    reparseData(upload){
      let alert = confirm("Confirm!");
      if (alert ) {
        this.spinner = true;
          this.baseService.save(environment.restPath+'apiFailure/reparse/'+upload.id,upload).subscribe((data)=>{
            
            this.spinner = false;

            this.reload();

          },
          (error) =>{
            this.spinner = false;
          }
          );
      }
    }



}
