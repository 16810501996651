import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from '../base.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  constructor(private sharedService:SharedService) { }

   public page_number:number=1;
   @Input() public totalCount : number;

  ngOnInit(): void {
    this.page_number=1
    this.sharedService.setPageNumber(this.page_number)
  }

  pageLeft(){


    if(this.page_number > 1){
    this.page_number = this.page_number-1;
    this.sharedService.setPageNumber(this.page_number)
    this.sharedService.lastPage = false;
    this.page_number = this.sharedService.getPageNumber();

    }
  }

  pageRight(){
    if((this.page_number*20)<this.totalCount){
    this.page_number = this.page_number+1;
    this.sharedService.setPageNumber(this.page_number)
    this.page_number =this.sharedService.getPageNumber();
    }

  }
  public greaterThan(num1: number, num: number) {
    return num1 >= num;
  }

}
