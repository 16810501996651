import { Injectable } from '@angular/core';
import {environment}from '../../environments/environment';
import { BaseService } from '../base.service';

@Injectable()
export class SearchService {

  constructor(private baseService:BaseService) { }

  search(type:string, field:string, input:string, filters:string)
  {
    let _URL = environment.restPath + 'search/type/'+ type + '/field/'+field + '/query/'+ input;
    if(filters != undefined)
    {
      _URL = _URL  + "?filters=" + encodeURIComponent(filters);
    }
    return this.baseService.getData(_URL);
  }
}
