import {Component, OnInit, ViewChild} from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PaginationComponent} from "../../pagination/pagination.component";
import {PaginationModule} from "../../pagination/pagination.module";
import { Router } from '@angular/router';


@Component({
  selector: 'app-usage-statistics',
  templateUrl: './usage-statistics.component.html',
  styleUrls: ['./usage-statistics.component.css'],
})
export class UsageStatisticsComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private baseService: BaseService,
    private sharedService: SharedService
  ) {}

  page: Number = 0;

  urlLink: String;

  totalCount: number;

  documenttoDelete: string = '';

  status = 'E';

  @ViewChild(PaginationComponent) page_comp: PaginationComponent;

  errorMsg: string = '';
  submitted: boolean = false;
  isFilterSet: boolean = false;
  allFiles: any;
  dateFilterForm: FormGroup;
  toggle = true;
  isSuperAdminUser = false;
  user: any;
  ngOnInit(): void {
    this.getAllFiles(this.sharedService.getPageNumber());
    this.user = localStorage.user ? JSON.parse(localStorage.user) : {};
    this.isSuperAdminUser = this.user.roles === 'super_admin';

    this.dateFilterForm = this.fb.group({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });
  }


  onFilterSubmit() {
    this.submitted = true;
    this.errorMsg = '';

    const startDate = new Date(this.dateFilterForm.controls.startDate.value);
    const endDate = new Date(this.dateFilterForm.controls.endDate.value);

    if (this.dateFilterForm.invalid) {
      this.errorMsg = 'Please enter a valid date';
    } else if (endDate.getTime() < startDate.getTime()) {
      this.errorMsg = 'Invalid End Date';
    } else {
      this.sharedService.setPageNumber(1);
      this.getFilteredFiles(this.sharedService.getPageNumber());
      this.page_comp.ngOnInit();
      document.getElementById('filter-pop-up-tab').style.display = 'none';
    }
  }

  getFilteredFiles(page) {
    this.urlLink =
      'filter/allFiles/' +
      page +
      '/startDate/' +
      this.dateFilterForm.controls.startDate.value +
      '/endDate/' +
      this.dateFilterForm.controls.endDate.value;

    this.baseService
      .getData(environment.restPath + this.urlLink)
      .subscribe((data) => {
        this.allFiles = data.result.list;
        this.totalCount = data.result.totalCount;
        this.isFilterSet = true;
      });
  }
  selectStatus(status: any): void {
    this.status = status;
    this.reload();
  }

  getAllFiles(page) {
    this.urlLink = 'file/list/page/' + page + '?status=' + this.status;
    this.baseService
      .getData(environment.restPath + this.urlLink)
      .subscribe((data) => {
        this.allFiles = data.result.list;
        this.totalCount = data.result.totalCount;
      });
  }

  downloadOutput(userActivity) {
    if (
      userActivity.outputDocumentLink &&
      userActivity.outputDocumentLink.trim().length > 0
    )
      window.location.href =
        environment.restPath +
        'file/download/reports/' +
        userActivity.outputDocumentLink;
  }

  downloadPdf(userActivity) {
    window.location.href =
      environment.restPath +
      'file/download/source/' +
      userActivity.sourceDocumentLink;
  }
  hasGapSheet(file) {
    if (
      file.documentFormat.trim() === 'CAMS' ||
      file.documentFormat.trim() === 'KARVY'
    ) {
      if (
        new Date(file.uploadTime).getTime() - new Date('07/23/2020').getTime() >
        0
      ) {
        return true;
      }
    }
    return false;
  }
  goToDashboard(file) {
    if (this.hasGapSheet(file)) {
      let documentJson = {
        id: file._id,
        outputDocumentLink: file.outputDocumentLink,
        sourceDocumentLink: file.sourceDocumentLink,
        documentName: file.documentName,
      };
      this.router.navigate(['/dashboard'], {
        queryParams: { upload: JSON.stringify(documentJson) },
      });

      this.sharedService.setGapSheetOriginCall('/usageStatistics');
    }
  }

  cancelFilter() {
    this.isFilterSet = false;
    this.toggle = true;
    this.sharedService.setPageNumber(1);
    this.reload();
    this.page_comp.ngOnInit();
    document.getElementById('filter-pop-up-tab').style.display = 'none';
  }

  reload() {
    if (!this.isFilterSet) this.getAllFiles(this.sharedService.getPageNumber());
    else this.getFilteredFiles(this.sharedService.getPageNumber());
  }

  // reloadFilter(){
  //   this.isFilterSet = true;
  // }
  clearFilter() {
    this.submitted = false;
    this.dateFilterForm.controls.startDate.reset();
    this.dateFilterForm.controls.endDate.reset();
  }
  clear() {
    this.isFilterSet = false;
    this.toggle = true;
    this.reload();
    this.dateFilterForm.controls.startDate.reset();
    this.dateFilterForm.controls.endDate.reset();
  }
  filter() {
    this.toggle = !this.toggle;
    document.getElementById('filter-pop-up-tab').style.display = 'block';
  }
}
