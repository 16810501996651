import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Injectable} from "@angular/core";


@Injectable()
export class PasswordValidation {

  public static MatchPassword(AC: AbstractControl) {
    let password = AC.value; // to get value in input tag
    let confirm_password = AC.get('confirm_password').value; // to get value in input tag
    if(password != confirm_password) {
      AC.get('confirm_password').setErrors( {passwordmatch: true} )
    } else {
      AC.get('confirm_password').setErrors(null);
      return null;
    }
  }

  public static PasswordRule(control: AbstractControl) {
    let password = control.value; // to get value in input tag
    let pattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}');
    if (!pattern.test(password)) {
       control.setErrors({['passwordrule']: true});
       return control
    } else if (password.toLowerCase() === 'something') {
        control.setErrors({['passwordrule']: true});
        return control
    } else {
      return null;
    }
  }


  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
}
