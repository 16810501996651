<div class="both">
    <div class="main_text">
        <div class="logo">
            <img class="Path" src="assets/img/MFIT_final.png">

        </div>
        <div class="logo_text_container">
            <span>MFIT TECHNOLOGIES
            </span>
        </div>
    </div>
  
    <video  id="run" class="video" autoplay="autoplay" >
  <source src="assets/Video/Mfit video1.mp4" type="video/mp4">
   
    </video>
   
 
    <div class="main"id="container_main">

        <div class="head_text">
            <span>Largest coverage of portfolio data sources</span>
        </div>
        <form class="login-form-main" #heroForm="ngForm">
            <div class="input">
                <div class="input_text"><label>Email</label></div>
                <input type="Email" class="loginForm_input" required email #email="ngModel" id="login-email"
                    placeholder="Example@gmail.com" name="email" ngModel>
                <div style="text-align:left; margin-left:10%!important"
                    *ngIf="email.errors&& (heroForm.submitted)&& heroForm.invalid">
                    <span class="text_danger" [hidden]="!email.errors.required">Email is required </span>
                    <span class="text_danger" [hidden]="!email.errors">Input should be email format </span>

                </div>
                <br>
                <div class="input_text"><span>Password</span></div>

                <input type="Password" class="loginForm_input" #password="ngModel" id="login-password"
                    placeholder="Password" required class="loginForm_input" name="password" ngModel>
                <div style="text-align:left;margin-left: 10%!important"
                    *ngIf="password.errors && ( heroForm.submitted) && heroForm.invalid"> </div>

                <div style="text-align:left;margin-left: 10%!important"
                    *ngIf="password.errors && ( heroForm.submitted) && heroForm.invalid">
                    <span class="text_danger" [hidden]="!password.errors.required">Password is required </span>

                </div>
                <br>
                <span class="text_danger">{{showMessage}}</span>
                <div class="main_div" id="div_forget">
                    <div class="remember_div">
                        <label class="container"><input type="checkbox" name="rememberMe" #rememberMe="ngModel"
                                ngModel><span class="checkmark"></span></label>
                        <label class="remember">Remember Me</label></div>
                    <div class="forgot_div">
                        <a (click)="gotoForgotPassword()" class="forgotpassword">Forgot Password?</a></div>
                </div>

                <button class="login" (click)="onSubmit(heroForm)">LOGIN</button><br>
                <button (click)="gotoSignUp()" class="signup">SIGNUP</button>
                <div class="terms">
                    <span class="terms_head">By logging in you are agreeing to,MFIT <a
                            href="https://staging.mfit.co.in/privacy.html" target="_blank">Privacy </a>&<a
                            href="https://staging.mfit.co.in/term.html" target="_blank">Terms.</a></span><br>

                    <!-- <a (click)="gotoResetPassword()" class="resetPassword">Reset Password?</a> -->
                </div>  
                <div class="company_logo">
                    <img class="mfit" src="assets/img/MFIT_final.png">
        
                </div>

            </div>
        </form>
      
</div>

</div>


 