<div class="pagination">

    <span class="page_number">{{(page_number-1)*20}}</span>
    <span *ngIf="!greaterThan((page_number*20),totalCount)" class="page_number"> - {{(page_number)*20}}</span>
    <span *ngIf="greaterThan((page_number*20),totalCount)" class="page_number"> - {{totalCount}}</span>
    <span class="page_number"> of {{totalCount}}</span>

    <span (click)="pageLeft()" class="icons material-icons" [ngClass]="page_number-1==0? 'icons-disabled':'icons'"    > navigate_before </span>  
    <span  (click)="pageRight()" class="icons material-icons"[ngClass]="greaterThan((page_number*20),totalCount)? 'icons-disabled':'icons'" >navigate_next </span>
                   
</div>