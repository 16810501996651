<div class="main_left_container">
<div class="both_tab">

      <div class="tab3">
      <button class="tablink3"onclick="openPage(event,'Payment')"><span class="btntxt">Payment</span></button></div>
      <div class="tab4">
      <button class="tablink4"(click)="gotoInvoice()"><span class="btnin">Invoice</span></button></div>
    </div> 
    <div class="invoice-tab">
    <span class="invoice">Invoice</span>
      </div>            
   <div class="rectangle_tab">
    <div class="both">
<div class="msg-tab">
<span class="oops">OOPS!!!</span>
</div>
<div class="msgtab">
<p class="you-dont-have-any-i">You don’t have any invoice
yet.<br />Your invoice will be displayed here once you have made
your first payment</p>
</div>
</div>
</div>
</div>


