import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
password:any;
submitted = false;
  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  passwordMatch :boolean
  onSubmit(form) {   
     if(form.controls.password.value !=form.controls.confirmPassword.value){

      this.passwordMatch = false;
      this.submitted = true;

    }
    else if(form.invalid){
      this.submitted = true;
    }
    else {
      this.router.navigate(['/login']);
    }
  }
}
