<section class="new-form-input">
  <div class="search">
    <div class="doc" [formGroup]="myGroup">
      <input  formControlName="queryField" type="text" id="{{controlId}}" autofocus autocomplete=off class="filter-tab" type="text" name="user-name" placeholder="{{placeholder}}">

      <i *ngIf="!show" class="material-icons search-icon" id="">search</i>
      <i *ngIf="show" (click)="closePopUp()" class="material-icons search-icon" >close</i>
    </div>

    <ul autofocus class="filter-select" *ngIf="show">
      <li *ngFor="let result of results" class="filter-select-list" (click)="onSearchResultSelect(result)">
        <p class="artist-name">{{result.name}}</p>
      </li>
    </ul>
  </div>

</section>
