 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UploadFileComponent } from './uploads/upload-file/upload-file.component';
import { LicenseManagementComponent } from './admin/license-management/license-management.component';
import { LoginComponentComponent } from './login/login-component/login-component.component';
import { LogoutComponent } from './login/logout/logout.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PricingComponent } from './pricing/pricing.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { SignUpComponentComponent } from './login/sign-up-component/sign-up-component.component';
import { ApprovedFilesComponent } from './uploads/approved-files/approved-files.component';
import { YetToBeApprovedFilesComponent } from './uploads/yet-to-be-approved-files/yet-to-be-approved-files.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { CompanyManagementComponent } from './admin/company-management/company-management.component';
import { NotificationsComponent } from './notifications/notifications/notifications.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { UsageStatisticsComponent } from './uploads/usage-statistics/usage-statistics.component';
import { SplitFilesComponent } from './uploads/split-files/split-files.component';
import { UploadOcrComponent } from './uploads/upload-ocr/upload-ocr.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { GapsheetFileComponent} from './dashboard/gapsheet-file/gapsheet-file.component';
 import {CanActivateSuperAdminFeaturesRouteGuard} from "./can-activate-superadmin-features-route.guard";
 import {CanActivateAdminFeaturesRouteGuard} from "./can-activate-admin-features-route.guard";
 import {APIComponent} from "./uploads/api/api.component";
 import { UploadMasterComponent } from './uploads/upload-master/upload-master.component';




const routes: Routes = [
  { path: '', redirectTo: '/upload', pathMatch: 'full' },
  { path:"companyManagement", component:CompanyManagementComponent,canActivate:[CanActivateSuperAdminFeaturesRouteGuard]},
  { path:"userManagement", component:UserManagementComponent,canActivate:[CanActivateAdminFeaturesRouteGuard]},
  { path:"upload", component:UploadFileComponent},
  { path:"splitFiles", component:SplitFilesComponent},
  { path:"uploadOcr", component:UploadOcrComponent},
  { path:"approvedUploads", component:ApprovedFilesComponent,canActivate:[CanActivateSuperAdminFeaturesRouteGuard]},
  {path:"yetToBeApprovedUploads", component:YetToBeApprovedFilesComponent,canActivate:[CanActivateSuperAdminFeaturesRouteGuard]},
  { path:"licenseManagement", component:LicenseManagementComponent},
  { path:"login", component:LoginComponentComponent},
  { path: "logout", component:LogoutComponent},
  {path:"my-profile", component:ProfileComponent},
  { path:"changePassword", component:ChangePasswordComponent},
  {path:"pricing",component:PricingComponent},
  { path:"resetPassword", component:ResetPasswordComponent},
  { path:"forgotPassword", component:ForgotPasswordComponent},
  { path:"signUp", component:SignUpComponentComponent},
  {path:"invoice",component:InvoiceComponent},
  { path:"usageStatistics", component:UsageStatisticsComponent},
  { path:"dashboard", component:DashboardComponent},
  { path:"gapsheetFile",component:GapsheetFileComponent},
  {path:"api",component:APIComponent,canActivate:[CanActivateAdminFeaturesRouteGuard]},
  { path:"uploadMaster", component:UploadMasterComponent,canActivate:[CanActivateSuperAdminFeaturesRouteGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
