<div class="both">
  <div class="main_text">
    <div class="logo">
      <img class="Path" src="assets/img/MFIT_final.png">
    </div>
    <div class="logo_text_container">
      <span>MFIT TECHNOLOGIES
      </span>
    </div>
  </div>
  <div class="main">
    <div class="head_text">
      <span>Create your account</span>
    </div>
    <div class="radio" >
      <label class="container" >Individual
        <input (click)="showContent()" type="radio"   value="radio1" name="customRadio" class="custom-control-input">
        <span class="checkmark"></span>
      </label>
      <div class="r2">
        <label class="container">Company
          <input (click)="showContent()" type="radio" value="radio2" name="customRadio" class="custom-control-input" [checked]="true">
          <span class="checkmark"></span>
        </label></div>
    </div>

    <div class="info"*ngIf="showcontent">
      <div class="info_data">
        <div class="inner_box">
                 <span class="headtext">
                  Reach out to us for demo at
                 	</span><br><span class="small_text">rahul@mfit.co.in or 9867231385.</span><br><span class="below_text">   Or submit details & email below.</span>
        </div>
      </div>
      <form class="Company-form" method="post" [formGroup]="companyform">
        <div class="input_tab">
          <div class="form-group">
            <div class="input_text"><span>Name</span></div>
            <input type="Name" formControlName="Cname"  class="signupForm_input "  id="Clogin-name" placeholder="Your Name"><br>
            <div *ngIf="submitted && Cname.errors &&!toggle" class="errorMessage">
              <span *ngIf="Cname.hasError('required')">UserName is required.</span>
              <span *ngIf="Cname.hasError('maxlength')">Only 10 characters allowed for UserName.</span>
              <span *ngIf="Cname.hasError('minlength')">Atleast 5 characters needed for UserName.</span>
              <span *ngIf="Cname.hasError('pattern')">Please enter vaild input for Username.</span>
            </div>
          </div>
          <div class="form-group">
            <div class="input_text"><span>Email</span></div>
            <input type="Email" formControlName="Cemail"  class="signupForm_input "  id="Clogin-email" placeholder="Your Email"><br>
            <div *ngIf="submitted && Cemail.errors &&!toggle" class="errorMessage">
              <span *ngIf="Cemail.hasError('required')">Email is required.</span>
              <span *ngIf="Cemail.hasError('email')">Please provide valid email.</span>
            </div>
          </div>
          <div class="form-group">
            <div class="input_text"><span>Phone Number</span></div>
            <input type="Phone" formControlName="Cphone"  maxlength="10" (keypress)=_keyPress($event)  class="signupForm_input " id="Clogin-phone" placeholder="Phone Number"><br>
            <div *ngIf="submitted && Cphone.errors &&!toggle" class="errorMessage">
              <span *ngIf="Cphone.hasError('required')">phone no is required.</span>
              <span *ngIf="Cphone.hasError('pattern')">Please provide valid phone no.</span>
            </div>
          </div>
          <div class="form-group">
            <button class="submit" (click)="onCompanySubmit()"  >SUBMIT</button><br>
          </div>
          <button (click) ="gotoLogin()" class="login">LOGIN</button>
          <div class="terms">
            <span>By signing up you are agreeing to,MFIT <a href="https://staging.mfit.co.in/privacy.html"target="_blank">Privacy </a>&<a href="https://staging.mfit.co.in/term.html"target="_blank">Terms.</a></span>
          </div>
        </div>

      </form>
    </div>


    <form class="individual-form" method="post" [formGroup]="valueform" >
      <div class="input_tab" *ngIf="!showcontent">
        <div class="form-group">
          <div class="input_text"><span>Name</span></div>
          <input type="Name" formControlName="name"  class="signupForm_input "  id="login-name" placeholder="Your Name"><br>
          <div *ngIf="submitted && name.errors&&!toggle"  class="errorMessage">
            <span *ngIf="name.hasError('required')">UserName is required.</span>
            <span *ngIf="name.hasError('maxlength')">Only 10 characters allowed for UserName.</span>
            <span *ngIf="name.hasError('minlength')">Atleast 5 characters needed for UserName.</span>
            <span *ngIf="name.hasError('pattern')">Please enter vaild input for Username.</span>
          </div>
        </div>
        <div class="form-group">
          <div class="input_text"><span>Email</span></div>
          <input type="Email" formControlName="email"  class="signupForm_input "  id="login-email" placeholder="Your Email"><br>
          <div *ngIf="submitted && email.errors&&!toggle" class="errorMessage">
            <span *ngIf="email.hasError('required')">Email is required.</span>
            <span *ngIf="email.hasError('email')">Please provide valid email.</span>
          </div>
        </div>

        <div class="form-group">
          <div class="input_text"><span>Phone Number</span></div>
          <input type="Phone" formControlName="phone"  maxlength="10" (keypress)=_keyPress($event)  class="signupForm_input "  id="login-phone" placeholder="Phone Number"><br>
          <div *ngIf="submitted && phone.errors&&!toggle" class="errorMessage">
            <span *ngIf="phone.hasError('required')">Phone number is required.</span>
            <span *ngIf="phone.hasError('pattern')">Please provide valid phone number.</span>
          </div>
        </div>


        <div class="form-group">
          <div class="input_text"><span>Password</span></div>
          <input type="Password" formControlName="password"  class="signupForm_input"  id="login-password" placeholder="Password"><br>
          <div *ngIf="submitted && password.errors&&!toggle" class="errorMessage">
            <span *ngIf="password.hasError('required')">Password is required.</span>
            <span *ngIf="password.hasError('maxlength')">Only 10 characters allowed for password.</span>
            <span *ngIf="password.hasError('pattern')">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character.</span>
          </div>
        </div>

        <div class="form-group">
          <div class="input_text"><span>Confirm Password</span></div>
          <input type="password" formControlName="cpassword"  class="signupForm_input " id="login-confirmpassword" placeholder="Confirm Password"><br>
          <div *ngIf="submitted && cpassword.errors&&!toggle" class="errorMessage">
            <span *ngIf="cpassword.hasError('required')">Confirm Password is required.</span>
            <span *ngIf="cpassword.hasError('mustMatch')">Password Must Match.</span>
          </div>
        </div>
        <div class="form-group">
        <button class="submit" (click)="onSubmit() " >SUBMIT</button><br>
        </div>
        <button (click) ="gotoLogin()" class="login">LOGIN</button>
        <div class="terms">
          <span class="terms_head">By  signing  in you are agreeing to,MFIT <a href="https://staging.mfit.co.in/privacy.html" target="_blank">Privacy </a>&<a
            href="https://staging.mfit.co.in/term.html" target="_blank">Terms.</a></span><br>
        </div>
      </div>
      <div class="company_logo">
        <img class="mfit" src="assets/img/MFIT_final.png">

    </div>
    </form>
  </div>
</div>



