<div class="main_left_container">
    <div class="login_form">

            <div class="both">
                <div class="tab">
                    <button class="tablink1" (click)="gotoProfile()">Profile</button>
                </div>
                <div class="tab2">
                    <button class="tablink2" (click)="gotoChangePassword()">Password</button>
                </div>
            </div>
            <div class="both_profile">
               <!-- <div class="profile">
                    <i class="material-icons" style="font-size:90px; color:#1F35BD; text-align:center;margin-left: 10px!important; width: 6%;">account_circle</i>
                </div>
                <div class="upload_label">
                 <button class="image"><span class="image_txt">Upload New Image</span></button>
                </div>-->
            </div>
            <form #heroForm="ngForm">
            <div class="form_box">
                <div class="input">
                    <div class="input_text"><span>Name</span></div>
                    <input type="text" name="user"  class="loginForm_input" [(ngModel)]="user.name"   required><br>

                    <div class="input_text"><span>Role</span></div>
                    <input type="text" name="user-role" class="loginForm_input"disabled [(ngModel)]="user.roles" placeholder="User Role"  ><br>

                    <div class="input_text"><span>Email</span></div>
                    <input type="email"  disabled class="loginForm_input" [(ngModel)]="user.email"  name="email" placeholder="Email"><br>

                    <div class="input_text"><span>Company Name</span></div>
                    <input type="text" disabled class="loginForm_input" name="company" [(ngModel)]="user.companyName" placeholder="Company Name" ><br>

                    <div class="input_text"><span>Address</span></div>
                    <input type="Address"required  name="address" [(ngModel)]="user.address" class="loginForm_input" placeholder="Address"ngModel>

                </div>
                <div class="right">
                    <div class="right_text"><span>Supervisior Name</span></div>
                    <input type="text" disabled class="loginForm_input" name="supervisor" [(ngModel)]="user.supervisor" placeholder="Supervisor Name"><br>

                    <div class="right_text"><span>Phone Number</span></div>
                    <input type="tel" required class="loginForm_right" name="phone number" [(ngModel)]="user.phoneNumber" placeholder="Phone Number"><br>
                    <span class="text_danger" [hidden]="phoneNumbervalid">Please enter a valid number</span>

                    <div class="right_text"><span>URL</span></div>
                    <input type="text" disabled class="loginForm_input"  name="companyUrl" [(ngModel)]="user.companyUrl" placeholder="www.companyname.com"><br>

<!--                    <div class="right_text"><span>State</span></div>-->
<!--                    <input type="text" required  name="state" class="loginForm_input"  id="login-state" placeholder="State"ngModel>-->
<!--                    <div style="text-align:left; margin-left:2%;margin-top:-2%!important" *ngIf="state.errors&& (heroForm.submitted)&& heroForm.invalid">-->
<!--                        <span class="text_danger" [hidden]="!state.errors.required">State Name is required</span>-->
<!--                        <span class="text_danger" [hidden]="!state.errors.pattern">Only letters and Minimum 4 characters</span>-->
<!--                    </div>-->
<!--                    <br>-->

<!--                    <div class="right_text"><span>City</span></div>-->
<!--                    <input type="City"required  pattern="[A-Za-z].{3,14}"name="city" class="loginForm_input" #city="ngModel" id="login-city" placeholder="City"ngModel>-->
<!--                    <div style="text-align:left; margin-left:2%;margin-top:-2%!important" *ngIf="city.errors&& (heroForm.submitted)&& heroForm.invalid">-->
<!--                        <span class="text_danger" [hidden]="!city.errors.required">City Name is required</span>-->
<!--                        <span class="text_danger" [hidden]="!city.errors.pattern">Only letters and Minimum 4 characters</span>-->
<!--                    </div>-->
                </div>
            </div>

            <div class="button">
                <button class="cancel">CANCEL</button>
                <button class="save" (click)="onSubmit()">SAVE</button>
            </div>
    </form>
    </div>
</div>
