import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {AppComponent} from 'src/app/app.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private appcomp: AppComponent , private router: Router, private sanitizer: DomSanitizer) { }

  @Input()

  expand = false;
  localStorage:WindowLocalStorage;
  currentLinkNo : number =0 ;
  activeLink : ActivatedRoute;
  sidebarBox="smallbox";
  menuItems:any = []; 
  private location: Location
  user:any;

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--some-var: ${"75%"}`);
  }

  ngOnInit(): void {
    this.user = localStorage.user? JSON.parse(localStorage.user):{};

    if(this.user.roles === "super_admin") {

      this.menuItems = [

        {"title": "Upload Pdf / Xls", "icon": "cloud_upload", "link": "/upload",},
        {"title": "All Files", "icon": "file_copy", "link": '/usageStatistics'},
        {"title": "Scanned Documents", "icon": "scanner", "link": '/uploadOcr'},
        {"title": "Split Files", "icon": "crop", "link": '/splitFiles'},
        {"title": "Gapsheet", "icon": "pie_chart", "link": '/gapsheetFile'},
        {"title":"API Failure","icon":"api", "link":'/api'},
        {"title": "User Management", "icon": "supervised_user_circle", "link": '/userManagement'},
        {"title": "Approval", "icon": "check_box", "link": '/approvedUploads'},
        {"title": "Company Management", "icon": "business", "link": '/companyManagement'},
        {"title": "Upload Master", "icon": "file_upload", "link": '/uploadMaster'},        
      // {"title": "Pricing", "icon": "attach_money", "link": '/pricing'},   

      ];
    }
    else if(this.user.roles === "admin"){

      this.menuItems = [

        {"title": "Upload Pdf / Xls", "icon": "cloud_upload", "link": "/upload",},
        {"title": "All Files", "icon": "file_copy", "link": '/usageStatistics'},
        {"title": "Scanned Documents", "icon": "scanner", "link": '/uploadOcr'},
        {"title": "Split Files", "icon": "crop", "link": '/splitFiles'},
        {"title": "Gapsheet", "icon": "pie_chart", "link": '/gapsheetFile'},
        {"title":"API Failure","icon":"api", "link":'/api'},
        {"title": "User Management", "icon": "supervised_user_circle", "link": '/userManagement'}];
    }
    else {

      this.menuItems = [

        {"title": "Upload Pdf / Xls", "icon": "cloud_upload", "link": "/upload",},
        {"title": "All Files", "icon": "file_copy", "link": '/usageStatistics'},
        {"title": "Scanned Documents", "icon": "scanner", "link": '/uploadOcr'},
        {"title": "Split Files", "icon": "crop", "link": '/splitFiles'},
        {"title": "Gapsheet", "icon": "pie_chart", "link": '/gapsheetFile'}
       ];
    }

        if (location.pathname == '/dashboard')
        {
          this.currentLinkNo=4;

        }
        else {
        for (let index = 0; index < this.menuItems.length; index++) {
          if (location.pathname === this.menuItems[index].link) {
            this.currentLinkNo = index;
          }
        }
        }
  }

  openSideBar()
  {
    this.expand = true;
    this.sidebarBox = "box";
    this.appcomp.menuIcon = "menu_open";
  }

  collapseSideBar()
  {
    this.expand = false;
    this.sidebarBox = "smallbox";
    this.appcomp.menuIcon = "menu";
  }

  

  onClick(path:any, index:number)
  {

    this.router.navigate([path]);
    this.expand = false;
    this.appcomp.menuIcon="menu";
    this.currentLinkNo = index;
    console.log(this.router.url)
  }

}
