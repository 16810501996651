import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthInterceptor} from './sessiontimeout-interceptor';
import { UploadsModule } from './uploads/uploads.module';
import { LoginModule } from './login/login.module';
import { AdminModule } from './admin/admin.module';

import { NotificationsModule } from './notifications/notifications.module';
import { SharedService } from './shared.service';
import { PaginationComponent } from './pagination/pagination.component';
import { PaginationModule } from './pagination/pagination.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DatePipe, registerLocaleData} from "@angular/common";
import {ProfileModule} from "./profile/profile.module";
import {CanActivateSuperAdminFeaturesRouteGuard} from "./can-activate-superadmin-features-route.guard";
import {CanActivateAdminFeaturesRouteGuard} from "./can-activate-admin-features-route.guard";
import {DateFilter} from "./date.filter";
import {SearchService} from "./auto-complete/search.service";
import {AutoCompleteModule} from "./auto-complete/auto-complete.module";
import {ToastrModule} from "ngx-toastr";
import { InvoiceComponent } from './invoice/invoice.component';
import { PricingComponent } from './pricing/pricing.component';
import localeENIN from '@angular/common/locales/en-IN';
import {ShortNumberPipe} from "./shortNumberPipe";

registerLocaleData(localeENIN, 'en-IN')

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DateFilter,
    InvoiceComponent,
    PricingComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    UploadsModule,
    LoginModule,
    AdminModule,
    NotificationsModule,
    DashboardModule,
    PaginationModule,
    BrowserAnimationsModule,
    ProfileModule,
    AutoCompleteModule,
    ToastrModule.forRoot(),

  ],

  providers: [

    {provide: LOCALE_ID, useValue: "en-IN"},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }, SharedService, DatePipe, ShortNumberPipe,
    CanActivateAdminFeaturesRouteGuard,
    CanActivateSuperAdminFeaturesRouteGuard,
    SearchService, BaseService, HttpClient

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
