import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import {MatProgressSpinner, MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";

import {ProgressSpinnerModule} from 'primeng/progressspinner';


@NgModule({
  declarations: [SpinnerComponent],
  exports: [
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    MatProgressSpinnerModule,
  ]
})
export class SpinnerModule { }
