<div class="br">
    <div class="right_box">
        <div class="rectangle_notify"><span class="notifications">Notifications</span></div>
        <div class="notifications_box">
            <div class="heading">
                <span class="notifications_txt">Congratulations! Your sign-up is complete!</span>
                <img src="img/3-dots-png-1.png"width="24px"style="margin-left:30px!important">
            </div>
            <div class="textnote">
                <span class="notifications_txt1">We have recieved all your documents and are happy to inform you that you are now fully signed up and officially a GroupBima POS! Let’s sell your first policy!</span>
            </div>
            <div class="time"><span class="time_txt">6hrs</span></div>
        </div>
        <div class="notifications_box">
            <div class="heading">
                <span class="notifications_txt">Congratulations! Your sign-up is complete!</span>
                <img src="img/3-dots-png-1.png"width="24px"style="margin-left:30px!important">
            </div>
            <div class="textnote">
                <span class="notifications_txt1">We have recieved all your documents and are happy to inform you that you are now fully signed up and officially a GroupBima POS! Let’s sell your first policy!</span>
            </div>
            <div class="time"><span class="time_txt">Yesterday</span></div>
        </div>
        <div class="notifications_box">
            <div class="heading">
                <span class="notifications_txt">Congratulations! Your sign-up is complete!</span>
                <img src="img/3-dots-png-1.png"width="24px"style="margin-left:30px!important">
            </div>
            <div class="textnote">
                <span class="notifications_txt1">We have recieved all your documents and are happy to inform you that you are now fully signed up and officially a GroupBima POS! Let’s sell your first policy!</span>
            </div>
            <div class="time"><span class="time_txt">Yesterday</span></div>
        </div>
        <div class="notifications_box">
            <div class="heading">
                <span class="notifications_txt">Congratulations! Your sign-up is complete!</span>
                <img src="img/3-dots-png-1.png"width="24px"style="margin-left:30px!important">
            </div>
            <div class="textnote">
                <span class="notifications_txt1">We have recieved all your documents and are happy to inform you that you are now fully signed up and officially a GroupBima POS! Let’s sell your first policy!</span>
            </div>
            <div class="time"><span class="time_txt">21Apr2019</span>
        </div>
        <div class="notifications_box">
            <div class="heading">
                <span class="notifications_txt">Congratulations! Your sign-up is complete!</span>
                <img src="img/3-dots-png-1.png"width="24px"style="margin-left:30px!important">
            </div>
            <div class="textnote">
                <span class="notifications_txt1">We have recieved all your documents and are happy to inform you that you are now fully signed up and officially a GroupBima POS! Let’s sell your first policy!</span>
            </div>
            <div class="time"><span class="time_txt">21Apr2019</span>
        </div>
    </div>
</div>

    </div>
</div>
