import {Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name:"datefilter"
})

export class DateFilter implements PipeTransform{
    transform (value:any, args:any):any{
        return new Date(value).toISOString();
    }
}
