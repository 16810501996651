import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { BaseService } from 'src/app/base.service';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

   showMessage="";

   email:any


  constructor(private router:Router,private sharedService:SharedService, private baseService:BaseService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.input is NOW valid !!
  }

  refreshMessage() {
    this.showMessage = "";
  }

  onSubmit(form) {
     this.email = form.controls.email.value;
    this.baseService.save(environment.restPath+'security/v1/forgotPassword', {"email":this.email}).subscribe(
      (response) =>
      {
        this.showMessage ="Please check your email for further details"

      }, (error)=>{
        this.showMessage ="User Doesnt Exist"
      });
  }
}

