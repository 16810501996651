import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import  {  FormsModule,  ReactiveFormsModule  }  from  '@angular/forms';
import { LoginComponentComponent } from './login-component/login-component.component';
import { SignUpComponentComponent } from './sign-up-component/sign-up-component.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";




@NgModule({
  declarations: [LoginComponentComponent, SignUpComponentComponent, ResetPasswordComponent, ForgotPasswordComponent, LogoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    
  ]
})
export class LoginModule { }
