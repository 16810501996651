<div class="main_left_container">
    <form name="loginForm">
        <div class="text_tab">
            <span class="uploadtxt">Upload Documents</span>
        </div>
        <div class="rectangle_box" appDnd (fileDropped)="onFileDropped($event)">
            <div class="upload_file">
                <img src="assets\img\upload.png" alt="upload" width="100px">
            </div>

            <div class="droplink">
                <p class="drop-files-here-or-c">
                    Drop files here or click <a href="" data-toggle="modal" data-target="#upload-file-tab"> select
                        to upload files</a></p>
             </div>
     </div>

        <!-- Uploding Files div starts. -->

        <div class="uploading-files-modal fade" id="upload-file-tab" role="dialog" [style.display]="showModal?'block':'none'">
            <div class="rectangle-main">
                <div class="uploading-file">
                    <span class="uploading-files">UPLOAD FILES</span>
                    <span data-dismiss="modal" (click)="closeModal()" class="close-text">CLOSE</span>
                </div>
                <form [formGroup]="uploadOuterFileForm" class="upload_popup_outercontainer" (ngSubmit)=uploadFiles($event)>
                    <div *ngIf="uploadOuterFileForm.controls.uploadItems['controls'].length>0">
                        <div formArrayName="uploadItems" *ngFor="let file of uploadOuterFileForm.get('uploadItems')['controls'] ; let i = index">
                            <div [formGroupName]="i">
                                <div class="upload_popup_innercontainer">
                                    <div class="up_icons">
                                        <img *ngIf="!file.controls.file.value.type.includes('spreadsheet')&& !file.controls.file.value.type.includes('excel')" class="up_icon_img" src="assets\img\PDF-8.png">
                                        <img *ngIf="file.controls.file.value.type.includes('spreadsheet')||file.controls.file.value.type.includes('excel')" class="up_icon_img" src="assets\img\XLS-8.png">


                                    </div>

                                    <div class="docname">
                                        <span *ngIf="greaterThan(file.controls.file.value.name.length, 20)" class="doc-name-bigText">{{(file.controls.file.value.name| slice:0:15)+' ... '+(file.controls.file.value.name| slice:file.controls.file.value.name.length-10:file.controls.file.value.name.length)}}</span>
                                        <span *ngIf="!greaterThan(file.controls.file.value.name.length, 20)" class="doc-name-bigText">{{(file.controls.file.value.name)}}</span><br>

                                    </div>
                                    <div class="docpass">
                                        <input type="text" type="password" formControlName="password" class="upload-popup-input" name="password" value="" placeholder="Document password">

                                    </div>

                                    <div class="remove-doc">
                                        <span (click)="removeDocFromPopUp(i)" class="material-icons">close</span>
                                    </div>
                                    <div class="line-dash"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div *ngIf="uploadOuterFileForm.controls.uploadItems['controls'].length==0" class="please-select-file">Please select file</div>

                  <app-spinner class="spinner" [showSpinner]="spinner"></app-spinner>

                    <div class="upload-popup-button-container">

                      <span class="upload-form-errorMsg">{{errorMsg}}</span>

                      <button type="button" class="add-more-file-btn">
                            <input #fileInput type="file" name="filesList" 
                                (change)="onSelectFile($event.target.files)" id="file" class="inputfile"
                                multiple />
                            {{uploadButtonText}}</button>
                        <button [disabled]="uploadOuterFileForm.controls.uploadItems['controls'].length==0" class="extract-file-btn"   >SUBMIT</button>
                    </div>
                </form>
            </div>
        </div>


        <!-- Uploding Files Part Finish Here. -->
    </form>

    <div class="middle_container">

        <div class="recent_title">
            <span class="txt">Recent Documents</span>
        </div>
        <button id="btn" (click)="goToUsageStatistics()"class="view_btn"><span class="view_txt" >VIEW All</span></button>
       
    </div>
    <div class="lower_container">
        <table cellpadding="2">
            <tr>
                <th class="first_col_th">Document Name</th>
                <!-- <th>Password</th> -->
                <th>Type</th>
                <th>
                    <div class="table_header">
                        <span class="header_name">Upload Time</span>

                    </div>
                </th>

                <th>Status</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let upload of uploads; let i = index">
                <td title="{{upload.documentName}}" class="first_col">{{upload.documentName}}</td>
                <!-- <td *ngIf="upload.inputDocumentPassword !=''">********</td>
                <td *ngIf="upload.inputDocumentPassword === ''"></td> -->
                <!-- {{upload.inputDocumentPassword}} -->
                <td class="third_col">{{upload.documentFormat}}</td>
                <td>{{upload.uploadTime|date }} <br><span class="time">{{upload.uploadTime|date :'shortTime' }}</span>
                </td>

                <td *ngIf="upload.approvalStatus==='PENDING'" >
                    <button class="Status-READY"> 
                        READY
                     </button>
                </td>
                <td *ngIf="upload.approvalStatus==='REJECTED'">
                    <button class="Status-ERROR">
                        ERROR
                     </button>

                </td>
                <td *ngIf="upload.approvalStatus==='APPROVED'">
                    <button class="Status-VALIDATED">
                        VALIDATED
                     </button>
                    </td>

                   <td>
                    <div class="Action">
                        <i class="material-icons md-get_app " (click)="downloadOutput(upload)">get_app</i>
                        <i class="material-icons md-visibility" (click)="goToDashboard(upload)"  [ngClass]="{
                            'visibility-enabled': hasGapSheet(upload),
                            'visibility-disabled':!hasGapSheet(upload) }">visibility</i>
                        <i class=" material-icons md-picture_as_pdf visibility-disabled " *ngIf="upload.sourceDocumentLink.trim()===''">picture_as_pdf</i>
                        <i class=" material-icons md-picture_as_pdf " *ngIf="upload.sourceDocumentLink!=''"  (click)="downloadPdf(upload) ">picture_as_pdf</i>
                        <i class=" fa fa-file-powerpoint-o material-icons md-visibility "  *ngIf="canDownloadPPT(upload)" (click) = "downloadPPT(upload)" ></i>
                        <i class="material-icons md-delete " (click)="fileToDelete(upload) " onclick="document.getElementById( 'delete-pop-up-tab').style.display='block' ">delete</i>
                     
                    </div>
                </td>
            </tr>
        </table>

    </div>
    <app-pagination [totalCount]="totalCount " (click)="reload() "></app-pagination>
</div>

<!-- Delete pop-up html starts -->

<div id="delete-pop-up-tab" class="delete-pop-up-modal">
    <div class="delete-outer-tab">
        <div class="delete-inner-tab">
            <div class="delete-warning-tittle-box">
                <label class="delete-warning-tittle">WARNING</label>
            </div>
            <div class="delete-warning-message ">

                <span>Are you sure you want to delete "{{documentToDeleteName}}" file ?</span>
            </div>
            <div class="delete-btn-tab">
                <button class="delete-cancel" onclick="document.getElementById('delete-pop-up-tab').style.display='none'">CANCEL</button>
                <button class="delete-yes" (click)="delete()" onclick="document.getElementById('delete-pop-up-tab').style.display='none'">YES</button>
            </div>
        </div>
    </div>
</div>


